import { AuthService } from './../../services/auth.service';
import { Component, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { IAddress, ICustomer } from '@signal/asp-data-commons';
import { DealerState } from '../../../store/dealer/dealer.reducer';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../../../store/dealer/dealer.selectors';
import { StateDetails } from './../../models/state';
import { SubscriptionList } from '../../models/asp.types';
import { countryCd, unsubscribeSubscriptions } from '../../services/util.service';
import { ServiceAppointmentState } from '../../../store/service-appointment/service-appointment.reducer';
import { selectStatesAndCitiesBasedOnZip } from '../../../store/service-appointment/service-appointment.selectors';
import { LoadStatesAndCities } from '../../../store/service-appointment/service-appointment.actions';
import { TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomerInfoComponent),
      multi: true
    }
  ]
})
export class CustomerInfoComponent implements ControlValueAccessor, OnDestroy, OnChanges, OnInit {
  @Input() location: string;
  @Input() department: string;
  @Input() remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
  @Input() from:string;
  customerInfo: UntypedFormGroup;
  hoursOfOperation$: Observable<string>;
  dealerName$: Observable<string>;
  dealerPhone$: Observable<string>;
  dealerAddress$: Observable<string>;
  amenities$: Observable<string[]>;
  states$: Observable<StateDetails[]>;
  cities$: Observable<{ [key: string]: string[] }>;
  signedIn: boolean;
  subscriptions: SubscriptionList = {};
  stateName: string;
  cityName: string;
  otherStateName: string;
  filteredOptions: Observable<string[]>;
  filteredcities: Observable<string[]>;
  states=[];
  cities=[];
  addressValidationList = ['addressLine1', 'city', 'state'];
  address:{
    addressLine1:'',
    addressLine2:'',
    state:'',
    city:'',
    zip:''
  }
  formStates = {
    "address": { states: [] }
  }
  formcities = {
    "address": { cities: { statecities: {} } }
  }
  stateCitites:{
    formStates,
    formcities
  }
  isZipValid:boolean=false;
  preferredCountries: string  = this.translate.currentLang? this.translate.currentLang.split('-')[1].toLowerCase(): 'us';
  remoteAddress="";
  dealerDetailsData$;
  dealerAddressForRadio;
  dealerPhoneNumber;

  onChange = (value: IAddress) => { };
  onTouched = () => { };

  get value(): IAddress {
    return this.customerInfo.value;
  }

  set value(value: IAddress) {
    this.customerInfo.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get addressControl() {
    return this.customerInfo as UntypedFormGroup;
  }

  ngOnChanges(changes: SimpleChanges): void {
    /**auto select correct address on the basis of transportation typeCode */
    if( changes?.location?.currentValue==="REMOTE" && changes?.location?.previousValue==="DEALER") {
      this.customerInfo.patchValue({
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              zip: '',
              country: countryCd
            });
    }

    if(this.location && changes?.location?.currentValue==="DEALER" && changes.location.currentValue !== changes.location.previousValue) {
      if(changes?.location?.previousValue==="REMOTE") {
        this.remoteAddress=this.customerInfo.value;
      }
      let dealerAddress = {...this.dealerAddressForRadio.address};
      if(!dealerAddress.addressLine2) {
        dealerAddress.addressLine2 = "";
      }
      this.selectDealerAddress(dealerAddress);
    }
    
    if(this.location && changes?.location?.currentValue==="REMOTE" && changes.location.currentValue !== changes.location.previousValue) {
      if(!this.remoteAddress) {
        this.remoteAddress = this.customerInfo.value;
      }
      this.selectDealerAddress(this.remoteAddress);
    }

    if (changes.hasOwnProperty('location')) {
      if (this.location === 'REMOTE') {
        this.customerInfo.setValidators(this.chooseVehicleValidator());
        this.customerInfo.updateValueAndValidity();
      } 
      else {
       }
    }
  }

  selectDealerAddress(address) {
    this.customerInfo.patchValue(address);
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(SESSION_STORAGE) private readonly storage: WebStorageService,
    private readonly dealerState: Store<DealerState>,
    private readonly authService: AuthService,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly translate: TranslateService) {

    this.subscriptions.auth = this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.initializeForm();

    this.subscriptions.formChangeSub = this.customerInfo.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });

    /* Dealer details from State*/
    this.getStateValue();
  }

  ngOnInit() {
    this.subscriptions.addressChange = this.addressControl.get('zip').valueChanges.subscribe(value=>{
      if(value && value.length===5){
        this.zipChange(value);
      }
    })
   }

  initializeForm() {
    this.dealerDetailsData$ = this.dealerState.select(fromSelectors.dealerDetailsData);
    this.subscriptions.dealerDetails = this.dealerDetailsData$.subscribe(data=>{
      this.dealerAddressForRadio = data;
    })

    this.customerInfo = this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        zip: [''],
        country: ['USA']
      });
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = phoneNumberString.replace(/\D/g, '');

    if (cleaned.length === 10 || (cleaned.length === 11 && cleaned[0] === '1')) {
        let intlCode = cleaned.length === 11 ? '+1 ' : '';
        let startIndex = cleaned.length === 11 ? 1 : 0;

        return intlCode + '(' + cleaned.slice(startIndex, startIndex + 3) + ') ' + cleaned.slice(startIndex + 3, startIndex + 6) + '-' + cleaned.slice(startIndex + 6);
    }

    return null;
  }

  getStateValue() {
    // this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    // this.dealerPhone$ = this.dealerState.select(fromSelectors.selectDealerPhoneNumber);
    // this.dealerAddress$ = this.dealerState.select(fromSelectors.selectDealerAddress);
    // this.hoursOfOperation$ = this.dealerState.select(fromSelectors.selectSalesOperationalHours);
    // this.amenities$ = this.dealerState.select(fromSelectors.selectSalesAmenities);

    this.states$ = this.dealerState.select(fromSelectors.selectStates);
    this.cities$ = this.dealerState.select(fromSelectors.selectCities);
    this.subscriptions.state = this.states$.subscribe(response=>this.states=response);
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
  }

  registerOnChange = (fn: any) => {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    if (this.location === 'REMOTE') {
      this.customerInfo.setValidators(this.chooseVehicleValidator());
    } else {
      this.reset('address');
    }
    return this.customerInfo.valid ? null : { customer: { valid: false } };
  }

  get f() {
    return this.customerInfo.controls;
  }

  login() {
    this.authService.loginB2c();
  }

  chooseVehicleValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const address = group as UntypedFormGroup;
      // const otherAddress = group.controls.otherAddress as FormGroup;

      if (this.location === 'REMOTE') {
        this.addressValidationList.forEach(item => {
          if (!address.get(item).value) {
            address.get(item).setErrors({ required: `${item} ${this.translate.instant('testDrive.isRequired')}` });
          }
        });
     }
      return;
    };
  }

  reset(type) {
    let address;
    if (type === 'address') {
      address = this.customerInfo as UntypedFormGroup;
    } else {
    
    }
    this.addressValidationList.forEach(item => {
      address.get(item).setErrors(null, { emitEvent: false });
    });
  }

  zipChange(value) {
    if (value && value.length === 5) {
      this.dispatchZip(value);
      this.getStateCities(value)
    }
  }
  
  dispatchZip(value){
    if (value && value.length >= 5) {
        this.serviceAppointmentState.dispatch(new LoadStatesAndCities({ zipPinCode: value }))
    }
  }
  getStateCities(value){
      const props = { zipCode: value };
      this.subscriptions.zipSub = this.serviceAppointmentState.select(selectStatesAndCitiesBasedOnZip,props).subscribe(response => {
        if(response){
          const control='address';
            this.mapZipCodeResponse(response,control);
        }
      });
  }
  private mapZipCodeResponse(response,control) {
    if (response.stateCity && response.stateCity.length > 0 && this.customerInfo.value.zip === response.postalCode) {
      this.isZipValid=true;
      if (response.stateCity.length === 1) {
        this.formStates[control].states = [response.stateCity[0].state];
        this.formcities[control].cities.statecities = response.stateCity;
        if (response.stateCity[0].cities.length === 1) {
          this.addressControl.patchValue(
            {state: response.stateCity[0].state,
             city: response.stateCity[0].cities[0] 
            })
        }
        else{
          this.addressControl.patchValue({ state: response.stateCity[0].state});
        }
      }
      else {
        let states = [];
        let cities = {}
        for (const statecities of response.stateCity) {
          states.push(statecities.state);
          cities = { ...cities, statecities: response.stateCity }
        }
        
        this.formStates[control].states = states;
        this.formcities[control].cities = cities;
      }
    }
    else {
      if (this.customerInfo.value.zip === response.postalCode) {
        this.isZipValid=false;
        this.addressControl.get('zip').setErrors({ required:  this.translate.instant('appointmentInformation.invalidZip')});
       this.addressControl.get('zip').setValidators(Validators.required);
      }
    }
  }
  updateAddressFields($event,pickUpAddress){
  }
}
