import { AuthService } from './../../shared/services/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Alert, Telematics } from '../../shared/models/dashboard/telematics.model';
import {
  SwiperComponent,
  SwiperConfigInterface,
  SwiperDirective,
  SwiperPaginationInterface,
  SwiperScrollbarInterface,
} from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';
import { UserVehicleService } from '../../shared/services/user-vehicle.service';
import * as util from '../../shared/services/util.service';
import { DateTime } from 'luxon';
import { EditServiceAppointmentFromDashboard } from '../../store/service-appointment/service-appointment.actions';
import { Store } from '@ngrx/store';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import { selectIsBeingCancelledByAppointmentId } from '../../store/dashboard/dashboard.selectors';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';
import { Observable } from 'rxjs';
import { SubscriptionList } from '../../shared/models/asp.types';
import { ISalesInfo, LocationType } from '@signal/asp-data-commons';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService} from '@ngx-translate/core';
import { AnalyticsService } from './../../shared/services/analytics.service';
import { DealerState } from '../../store/dealer/dealer.reducer';
import * as fromSelectors from '../../store/dealer/dealer.selectors';
import { SwiperOptions } from 'swiper';
import { A11yOptions } from 'swiper/types/components/a11y';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit, OnDestroy {
  /* vehicle info to be displayed in the card */
  @Input() vehicle: any;
  @Input() ownedVehicle: boolean;
  @Output() appointmentCancel: EventEmitter<number> = new EventEmitter<number>();

  telematics: Telematics;
  setDefaultImg = util.setDefaultImg;
  vinDisplayText: string;
  model:string;
  makeModelYearText: string;
  appointmentExists: boolean;
  appointmentDateTime: string;
  carImageAbsoluteURL: string;
  dealerName: string;
  dealerPhone: string;
  appointmentLocationType: string;
  appointmentLocationAddress: string;
  appointmentsCount:number;
  signedIn: boolean;
  index : number = 0;
  isCancelFlipCard = false;
  isAppointmentFlipCard = false;
  flipCancelCard = false;
  isIssueFlipCard = false;
  isFlipCard = false;
  flipTo = '';
  isTelematicsLoading = true;
  enableDrive:boolean;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  disclaimerText: string;
  isBeingCancelled$: Observable<boolean>;
  private readonly pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
  };
  public config: SwiperConfigInterface = {
    a11y: {
      enabled: true,
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
    } as A11yOptions,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
  } as SwiperOptions;
  private readonly scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  };
  private readonly subs: SubscriptionList = {};
  salesSettings$:Observable<ISalesInfo>;
  constructor(private readonly router: Router,
    private readonly userVehicleService: UserVehicleService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly authService: AuthService,
    private readonly copy: Clipboard,
    private readonly analyticsService : AnalyticsService,
    private readonly dealerState: Store<DealerState>,
    private readonly translate: TranslateService) {
    this.disclaimerText = util.GLOBAL_CONSTANT.telematicsDisclaimerText;
    this.getDealerStateValue();
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
  }

  ngOnInit(): void {
    this.vinDisplayText = `VIN ${this.vehicle.vin || this.translate.instant('dashboardAppointments.notAvailable')}`;
    this.makeModelYearText = util.getMakeModelYearText(this.vehicle);
    this.appointmentExists = this.vehicle.appointment?.length > 0 || false;
    this.dealerName = this.vehicle?.dealer?.dealerName;
    this.dealerPhone = this.vehicle?.dealer?.phoneNumber;
    this.appointmentDateTime = this.appointmentExists ? this.getAppointmentDateTime() : '';
    this.appointmentsCount = this.vehicle.appointment?.length || 0;
    const index= this.vehicle.description.indexOf(" ");
    this.model=this.vehicle.description.substr(index);
    this.salesSettings$?.subscribe(item=> {     
      this.enableDrive= item.enableLearning 
    })

    this.getLocationType();
    // TODO - Store generic image URL in prop/env
    this.carImageAbsoluteURL = util.getCarImageAbsoluteURL(this.vehicle.imageUrl);
    if (this.vehicle.appointment && this.vehicle.appointment[this.index]) {
      const props = { appointmentId: this.vehicle.appointment.appointmentId };
      this.isBeingCancelled$ = this.dashboardState.select(selectIsBeingCancelledByAppointmentId, props);
    }


    this.authService.user.subscribe(user => {
      if (user && !this.authService.isExpired()) {
        this.fetchTelematics(user?.extension_tmsguid);
      }
    });
  }

  getAppointmentDateTime() : string {
    const dateTime = DateTime.fromISO(this.vehicle.appointment[this.index].appointmentStartDate)
    .toFormat('MMM dd') + ' at ' + this.vehicle.appointment[this.index].timeSlotId;
    return this.appointmentExists ? dateTime : '';

  }
  
  getDealerStateValue(){
    this.salesSettings$ = this.dealerState.select(fromSelectors.selectSalesDetails);
  }
  getTelematicsAlerts() {
    return this.telematics.alerts;
  }

  doFlipCard() {
    this.flipTo = 'issue';
    this.isIssueFlipCard = !this.isIssueFlipCard;
    this.isFlipCard = this.isIssueFlipCard;
  }

  callDealer(){
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'contact dealer clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
  }

  onCancelFlip() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'cancel appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    this.flipTo = this.appointmentsCount === 1 ? '' : 'all-appointments';
    this.isFlipCard = false;
    if(this.appointmentsCount !== 1){
      setTimeout(() => {
        this.isFlipCard = true;
      }, 250);
    }
  }

  onCancelAppointment(){
    this.flipTo = "cancel";
    this.isFlipCard = false;
    setTimeout(() => {
      this.isFlipCard = true;
    }, 250);
  }

  cancelAppointmentFlip(){
    this.flipTo = "";
    this.isFlipCard = false;
  }

  onCancel() {
    this.appointmentCancel.emit(this.index);
  }

  onEditAppointment() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'edit appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    const payload = {
      id: this.vehicle.appointment[this.index].appointmentId,
    };

    this.serviceAppointmentState.dispatch(new EditServiceAppointmentFromDashboard());
    util.navigateToRoute('service', '/service', this.router, { queryParams: payload });
  }

  onCreateAppointment() {
    util.navigateToRoute('service', '/service', this.router,
      { queryParams: { vehicleId: this.vehicle.vehicleId } });
  }

  onCreateLearningAppointment(){
    util.navigateToRoute('Learning', '/learning', this.router,
      { queryParams: { vehicleId: this.vehicle.vehicleId } });
  }

  getEditBtnText() {
    const apptType = this.vehicle.appointment[this.index].appointmentType ? ` ${this.vehicle.appointment[this.index].appointmentType} ` : ' ';
    if(apptType === "Service")
    {
      return this.translate.instant('dashboardAppointments.editServiceAppointment');
    }
    else if(apptType === "Test Drive")
    {
      return this.translate.instant('dashboardAppointments.editTestDriveAppointment');
    }
    else if(apptType === "Delivery"){
      return this.translate.instant('dashboardAppointments.editDeliveryAppointment');
    }
    else{
      return this.translate.instant('confirm.editAppointment');
    }
  }

  getEditCancelBtnText() {
    let apptType = this.vehicle.appointment[this.index].appointmentType ? `${this.vehicle.appointment[this.index].appointmentType}` : ' ';
    apptType = apptType?.replace(' ','');
    return apptType ?`dashboardAppointments.editcancel${apptType}Appointment`: `dashboardAppointments.editcancelAppointment`;
  }

  seeAllAppointmentsBtnText(){
    return `${this.translate.instant('dashboardAppointments.seeAllAppointments')} (${this.appointmentsCount})`
  }

  seeAllApppointments(){
    this.flipTo = "all-appointments";
    this.isAppointmentFlipCard = !this.isAppointmentFlipCard;
    this.isFlipCard = true;
  }

  onEditCancelAppointment(){
    this.flipTo = "editcancel-appointments";
    this.isAppointmentFlipCard = !this.isAppointmentFlipCard;
    this.isFlipCard = true;
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

  getDealerAddress() {
    if (this.appointmentLocationType) {
      if (LocationType.DEALER === this.appointmentLocationType) {
        const dealer = this.vehicle.appointment[this.index].dealer;
        this.appointmentLocationAddress = `${dealer.address.addressLine1}, ${dealer.address.city}, ${dealer.address.state} ${dealer.address.zip}`;
      } else {
        const customer = this.vehicle.appointment[this.index].pickUpAddress ? this.vehicle.appointment[this.index].pickUpAddress : this.vehicle.appointment[this.index].customer.address;
        this.appointmentLocationAddress = customer.addressLine1 + ', ' + (customer.addressLine2 ? customer.addressLine2 +
          ', ' : '') + customer.city + ', ' + customer.state + ' - ' + customer.zip;
      }
    }
  }

  getLocationType() {
    if (this.vehicle.appointment && this.vehicle.appointment[this.index]) {
      this.appointmentLocationType = this.vehicle.appointment[this.index]?.transportation.category;
      this.getDealerAddress();
    }
  }

  onNavigateToGmap() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'at dealership clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    if (this.vehicle.appointment[this.index].dealer && this.isAtDealership) {
      const link = `https://www.google.com/maps?z=12&t=m&q=loc:${this.vehicle.appointment[this.index].dealer.latitude}+${this.vehicle.appointment[this.index].dealer.longitude}`;
      util.navigateToRoutePromise(undefined, this.router)
      .then(result => {
        window.open(link, '_blank');
      });
    }
  }

  get isAtDealership() {
    return this.appointmentLocationType === LocationType.DEALER;
  }

  private fetchTelematics(userCd: string) {

    this.isTelematicsLoading = true;
    this.userVehicleService.getVehicleHealthReport(userCd, this.vehicle.vehicleId, this.vehicle.vin)
      .subscribe(data => {
        if (data.status.code === '200') {
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          this.telematics = Telematics.getTelematics(vehicleStatus,vehicleAlerts);
          this.isTelematicsLoading = false;
        } else {
          this.fetchSafetyCampaigns();
        }

      }, err => {
        this.fetchSafetyCampaigns();
        this.isTelematicsLoading = false;
      });
  }

  private fetchSafetyCampaigns() {
    this.telematics = Telematics.getDefaultTelematics();
    this.userVehicleService.getSpecialServiceCampaign(this.vehicle.vin).subscribe(sscData => {
      if(sscData.status.code === '200'){
        this.telematics.alerts = sscData.vehicleCampaignList.map(val => <Alert>{ message: val.campaignTitle });
      }
      this.isTelematicsLoading = false;
    });
  }

  onHistoryClicked() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'appointment history clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    util.navigateToRoutePromise(util.getRouteFromWindow('dashboard') ? `${util.getRouteFromWindow('dashboard')}/servicehistory` : 'dashboard/servicehistory', this.router, {
      queryParams: {
        id: this.vehicle.vehicleId
      }
    });
  }

  onCopyDetails() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'copy details clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    this.copy.copy(this.convertToText());
  }

  convertToText() {
    let text = '';
    text += `${util.getMakeModelYearText(this.vehicle)}\n`;
    text += `VIN ${this.vehicle.vin || this.translate.instant('dashboardAppointments.notAvailable')}`;
    if (this.vehicle.appointment) {
      text += `\n${this.translate.instant('testDrive.serviceAppointmentIsScheduledFor')} ${this.appointmentDateTime}`;
      text += `\n${this.translate.instant((this.appointmentLocationType==='At Dealership' ? 
      'dashboardAppointments.atDealership' :  'dashboardAppointments.atRemote'))}: ${this.appointmentLocationAddress}`;
    }
    return text;
  }

  incrementIndex(){
    if(this.index<this.appointmentsCount-1){
      this.index += 1;
    }
    this.getLocationType();
  }

  decrementIndex(){
    if(this.index > 0){
      this.index-=1;
    }
    this.getLocationType();
  }
  getAlert(alert){
    if(alert){
    const alertArray = alert?.split(' ');
    const alertValue = alertArray[0].toLowerCase()+alertArray[1];
    if(alertArray[0].match(/^[A-Za-z]+$/)){
     return this.translate.instant(`dashboardAppointments.${alertValue}`);
    }else{
      return `${alertArray[0]} ${this.translate.instant(`dashboardAppointments.${alertArray[1].toLowerCase()}`)}`
    }
  }
  }
  getTranslation(value){
    if(value ){
      const translatedTxt = value?.split(':');
      return this.translate.instant(`${translatedTxt[0]}`) +(translatedTxt[1]==='(Not available)' ? `(${this.translate.instant(`dashboardAppointments.notAvailable`)})` : translatedTxt[1]);
    }
  }
}
