import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { DateTime } from 'luxon';
import * as fromSelectors from '../store/sales-appointment/sales-appointment.selectors';
import {  take } from 'rxjs/operators';
import * as fromActions from '../store/sales-appointment/sales-appointment.actions';
import { SalesAppointmentState } from '../store/sales-appointment/sales-appointment.reducer';
import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SubscriptionList } from '../shared/models/asp.types';
import { ICustomer } from '@signal/asp-data-commons/lib/interfaces/appointment';
import { AppointmentType } from '@signal/asp-data-commons/lib/enums/enum';
import { getRouteFromWindow, inDealerSite, navigateToRoutePromise, unsubscribeSubscriptions } from '../shared/services/util.service';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { AuthService } from '../shared/services/auth.service';
import _ from 'lodash';
import { SelectLearningVehicleComponent } from '../shared/select-learning-vehicle/select-learning-vehicle.component';
@Component({
  selector: 'app-tech-appointment',
  templateUrl: './tech-appointment.component.html',
  styleUrls: ['./tech-appointment.component.scss']
})
export class TechAppointmentComponent implements OnInit, AfterViewInit {
  techAppointmentForm: UntypedFormGroup;
  availability: UntypedFormControl;
  appointmentComments: UntypedFormControl;
  availabilityOptions$: any;
  availabilityOptionsLoading$: any;
  availability$: any;
  availabilityLoading$: any;
  transportOptions$: any;
  remoteZipcodes$: Observable<string[]>;
  yearList$: any;
  hasRemoteLocation: boolean =  false;
  remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
  isValidRemoteZip = false;
  transport: any;
  isStoreUpdate = false;
  subscriptions: SubscriptionList = {};
  private readonly customerForm: UntypedFormControl;
  customerDetails$: Observable<ICustomer>;
  private signedInUserDetails: ICustomer;
  isSubmitting = false;
  appointmentId: string;
  inDealerSite = false;
  DEALER = 'DEALER'
  lastValue;
  editBool:boolean = false;
  oldValue: any;
  signedIn: boolean;
  profile: any;
  dateFormat : string = 'yyyy-MM-dd';
  allowed: boolean = false;
  @ViewChild(SelectLearningVehicleComponent, { static: false }) selectVehicleComponent: SelectLearningVehicleComponent;
  apptType :string =AppointmentType.LEARNING;
  languageId=''

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly bannerService: AspBannerService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly translateService:TranslateService
  ) { 
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.authService.user.subscribe(user => {
      this.profile = user;
    });
    this.initTechForm();

    this.salesAppointmentState.dispatch(new fromActions.LoadSeriesAndTrim());
    this.salesAppointmentState.dispatch(new fromActions.LoadAvailabilityOptions({appointmentType: AppointmentType.LEARNING}));

        /* logic to edit appointment */
    this.subscriptions.route = this.route.queryParams.subscribe(params => {
      this.appointmentId = params.id;
      if (this.appointmentId) {
        this.editBool = true;   
        this.salesAppointmentState.dispatch(new fromActions.GetEditData
          ({appointmentType: AppointmentType.LEARNING, appointmentId: this.appointmentId }));
       
          this.subscriptions.appointmentDetails = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((value) =>{
            if (_.isEqual(this.lastValue, value)) {
              return;
            }
            this.lastValue = value;
            this.languageId=value?.availability?.languageId;
            this.techAppointmentForm.patchValue(value);
          })

      }
    });     
  }

  ngAfterViewInit(): void {
  }

  ngAfterContentChecked(): void {
  
  }

  ngOnInit(): void {
    this.inDealerSite = inDealerSite();
    this.bannerService.update('');
    this.bannerService.show();
    this.setSignedInUserDetails();

    this.availabilityOptions$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptions);
    this.availabilityOptionsLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptionsLoading);
    this.availabilityLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityLoading);
    this.availability$ = this.salesAppointmentState.select(fromSelectors.getAvailability);
    this.transportOptions$ = this.salesAppointmentState.select(fromSelectors.getTransportOptions);
    this.remoteZipcodes$ = this.salesAppointmentState.select(fromSelectors.getPMAZipCodes);
    this.yearList$ = this.salesAppointmentState.select(fromSelectors.getYearList);
    
    if(!this.editBool){
      this.subscriptions.appointmentDetails = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((value) =>{
      
        if (_.isEqual(this.lastValue, value)) {
          return;
        }
        this.lastValue = value;
        
        this.techAppointmentForm.patchValue(value);
      })
    }
  }
  
  initTechForm(){
    
    this.techAppointmentForm = this.formBuilder.group({
      transportationTypeCode: ['DEALER'],
      remoteZipCode: [''],
      availability: this.availability,
      customer: this.customerForm,
      appointmentComments: this.appointmentComments
    });
    this.techAppointmentForm.valueChanges.subscribe((data)=>{
      this.onValueChange();
    })
  }

  //
  

  onValueChange() {
    const value = {...this.techAppointmentForm?.value};
    this.isStoreUpdate = false;
    if(this.checkOldValue(value)){
      this.isStoreUpdate = true;
      return;
    }
    if((value.availability?.advisorId && this.oldValue.availability?.advisorId !== value.availability.advisorId)
    || (value.transportationTypeCode && this.oldValue?.transportationTypeCode && this.oldValue?.transportationTypeCode !== value.transportationTypeCode)) {
      if (value.availability.appointmentStartDate) {
        const date = DateTime.fromFormat(value.availability.appointmentStartDate, this.dateFormat)
          .startOf('month')
          .toFormat(this.dateFormat);
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
          {date, advisorId: value.availability.advisorId, transport: value.transportationTypeCode, vin:'',
          appointmentType: AppointmentType.LEARNING, appointmentId: this.appointmentId }));
      }
      else{
        const advisor =value.availability.advisorId ? value.availability.advisorId : '-1';
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
          {date: '', advisorId: advisor, transport: value.transportationTypeCode, vin:'',
          appointmentType: AppointmentType.LEARNING, appointmentId: this.appointmentId }));
      }
    }
    this.oldValue = value;

    /**in editmode when form value will be loaded perfectly then it will be allowed to update the state. It will look for this change onetime, next time onward form will detect changes accordingly. */
    if(this.editBool && !this.allowed) {
      if(this.isValidForm()) {
        this.allowed = true;
      }
    }
    /** normal flow, this form will be allowed to do the changes anytime. */
    if(!this.editBool) {
      this.allowed = true;
    }

    if(this.allowed && !this.isStoreUpdate){
      this.isStoreUpdate = true;
      this.salesAppointmentState.dispatch(new fromActions.UpdatePatchSalesForm({ path: 'mergeAll', value }));
    }
  }

  checkOldValue(newValue) {
    return _.isEqual(this.oldValue, newValue);
  }
  
  setSignedInUserDetails() {
      if (this.signedIn) {
        this.signedInUserDetails = {
          firstName: this.profile.given_name,
          lastName: this.profile.family_name,
          emailAddress: this.profile.email,
          phoneNumber: '',
          address:{
                addressLine1:'',
                addressLine2:'',
                state:'',
                city:'',
                zip:'',
                country: 'USA'
              }
        };
      }else{
        this.signedInUserDetails = {
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '',
          address:{
                addressLine1:'',
                addressLine2:'',
                state:'',
                city:'',
                zip:'',
                country: 'USA'
              }
        };
      }
      let testComments = "";
        this.techAppointmentForm.patchValue({customer: this.signedInUserDetails, appointmentComments: this.appointmentComments});
        this.salesAppointmentState.dispatch(new fromActions.LoadCustomerDetails({ signedInUserDetails: this.signedInUserDetails }));
  }

  get getDisclaimerText() {
    return this.translateService.instant('testDrive.privacyPolicyTxt');
  }

  getTranslation(value){
    return value ? `${this.translateService.instant(`common.${value}`)}` : value;
  }

  onSubmit() {
    this.isSubmitting = true;
    this.onValueChange();
    const payload = {appointmentType : AppointmentType.LEARNING}
    if(this.editBool){
      this.salesAppointmentState.dispatch(new fromActions.EditSalesAppointment({appointmentType:AppointmentType.LEARNING,appointmentId:null}));
    }
    else{
      this.salesAppointmentState.dispatch(new fromActions.CreateSalesAppointment(payload));
    }
    this.subscriptions.editDetail = this.salesAppointmentState.select(fromSelectors.editDetails)
      .subscribe((editDetail) => {
        this.onSubmitting(editDetail);
      });
  }

  onSubmitting(data) {
        if(data){
          this.isSubmitting = true;
          if (data.isSubmitted) {
            this.appointmentId = data.appointmentId;
            const urlState = data.appointment;   
            const navigationExtras: NavigationExtras = {
              queryParams: {
                id: this.appointmentId
              },
              state: urlState
            };
            navigateToRoutePromise(getRouteFromWindow('learning') ? `${getRouteFromWindow('learning')}/confirm` : '/learning/confirm', this.router, navigationExtras);
          }
        }
  }

  isValidForm() {
    const vehicle = {...this.selectVehicleComponent?.step?.value};
    const apptStartDate = this.techAppointmentForm.get('availability').value?.appointmentStartDate || "";
    const currentDate = DateTime.fromISO(DateTime.now().toString())
    .toFormat(this.dateFormat);
    return this.techAppointmentForm.valid && this.checkVehicleValues(vehicle) && apptStartDate>=currentDate;
  }

  checkVehicleValues(vehicle){
    if(vehicle.make !== "" && vehicle.model !== "" && vehicle.year !== ""){
      return true;
    }
    else if(vehicle.vin?.length === 17){
      return true;
    }
    return false;
  }

  onMonthChange($event: any) {
    this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
       .pipe(take(1))
       .subscribe(details => {
        const date = $event.toFormat('yyyy-MM-dd');
         this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
           {date, advisorId: details.advisorId, transport: details.transport, vin:'',
           appointmentType: AppointmentType.LEARNING, appointmentId: this.appointmentId}));
       });
  }

  backToDashboard() {
    this.router.navigate(['asp/bdc/dashboard']);
  }
  
  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
    this.salesAppointmentState.dispatch(new fromActions.ResetSalesAppointmentState());
    this.bannerService.update('');
  }

}
