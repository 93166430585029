import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as fromActions from './dealer.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { IDealerInfo, IResponse } from '@signal/asp-data-commons';
import { DealerState } from './dealer.reducer';
import { DealerAdminService } from '../../shared/services/asp/dealer-admin.service';
import { ISettingsResponse, Settings } from './../../shared/models/settings/settings.model';
import { SettingsAdapter } from './dealer.adapter';
import { StateDetails } from '../../shared/models/state';

@Injectable({
  providedIn: 'root'
})
export class DealerEffects {

  constructor(private readonly actions$: Actions,
    private readonly store$: Store<DealerState>,
    private readonly dealerAdminService: DealerAdminService) { }

  
  LoadDealerDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadDealerDetails>(
      fromActions.DealerTypes.LOAD_DEALER_DETAILS
    ),
    mergeMap((action: fromActions.LoadDealerDetails) =>
      this.dealerAdminService.getSettings().pipe(
        map((dealerInfo: IResponse<IDealerInfo>) =>
          new fromActions.LoadDealerDetailsSuccess({ dealerInfo: dealerInfo.data })),
        catchError(err => of(new fromActions.LoadDealerDetailsFail({ error: err })))
      ))));

  
  loadSettings: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadSettings>(
      fromActions.DealerTypes.LOAD_SETTINGS
    ),
    mergeMap((action: fromActions.LoadSettings) =>
      this.dealerAdminService.getGeneralSettings(action.payload.departments).pipe(
        switchMap((settings: ISettingsResponse) => SettingsAdapter.toSettings(settings)),
        map((settings: Settings) => new fromActions.LoadSettingsSuccess({ settings })),
        catchError(err => of(new fromActions.LoadSettingsFailure({ error: err })))
      )
    )
  ));

  
  loadStates: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadStatesDetails>(
      fromActions.DealerTypes.LOAD_STATES
    ),
    mergeMap((action: fromActions.LoadStatesDetails) =>
      this.dealerAdminService.getStates().pipe(
        map((states: IResponse<StateDetails[]>) =>
          new fromActions.LoadStatesDetailsSuccess({ states:states.data })),
        catchError(err => of(new fromActions.LoadStatesDetailsFail({ error: err })))
      ))));

  
  loadCities: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadCityDetails>(
      fromActions.DealerTypes.LOAD_CITIES
    ),
    mergeMap((action: fromActions.LoadCityDetails) =>
      this.dealerAdminService.getCities().pipe(
        map((cities: IResponse<{ [key: string]: string[]}> ) =>
          new fromActions.LoadCityDetailsSuccess({ cities:cities.data })),
        catchError(err => of(new fromActions.LoadCityDetailsFail({ error: err })))
      ))));

}
