import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserVehicleService} from '../../shared/services/user-vehicle.service';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {IToyotaLexusModelDetails, ServiceAppointmentState} from './service-appointment.reducer';
import {AppointmentService} from '../../shared/services/asp/appointment.service';
import * as fromActions from './service-appointment.actions';
import * as fromSelectors from './service-appointment.selectors';
import {factoryRecommendedServices, IAvailability, IAvailabilityOptions, IOpcodeResponse, IResponse} from '@signal/asp-data-commons';
import {DealerAdminService} from '../../shared/services/asp/dealer-admin.service';
import {toData} from '../app.adapter';
import {CatalogService} from '../../shared/services/asp/catalog.service';

@Injectable()
export class ServiceAppointmentEffects {

  
  loadModelYearAndModels: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadModelYearAndModels>(
      fromActions.ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS
    ),
    mergeMap((action: fromActions.LoadModelYearAndModels) =>
      this.userVehicleService.getSeriesData().pipe(
        map((vehicleModelYearList: IToyotaLexusModelDetails) => new fromActions.LoadModelYearAndModelsSuccess({vehicleModelYearList})),
        catchError(err => of(new fromActions.LoadModelYearAndModelsFail({error: err})))
      )
    )
  ));

  
  loadServiceRequests: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadServiceRequests>(
      fromActions.ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS
    ),
    switchMap((action: fromActions.LoadServiceRequests) => {
        const payload = action instanceof fromActions.LoadServiceRequests ? action.payload : undefined;
        return this.catalogService.getServiceOpcodes(payload.opcodeRequest, payload.countryCode, payload.languageCode).pipe(
          switchMap((opcodeResponse: IResponse<IOpcodeResponse>) => toData(opcodeResponse, 201)),
          map((opcodeResponse: IOpcodeResponse) => new fromActions.LoadServiceRequestsSuccess({opcodeResponse})),
          catchError(err => of(new fromActions.LoadServiceRequestsFail({error: err})))
        );
      }
    )
  ));

  
  loadAvailableMileages: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadServiceRequests>(
      fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES
    ),
    switchMap((action: fromActions.LoadAvailableMileages) => {
        return this.catalogService.getAvailableMileages().pipe(
          switchMap((opcodeResponse: IResponse<number[]>) => toData(opcodeResponse)),
          map((availableMileages: number[]) => new fromActions.LoadAvailableMileagesSuccess({availableMileages})),
          catchError(err => of(new fromActions.LoadAvailableMileagesFail({error: err})))
        );
      }
    )
  ));

  
  loadAvailabilityOptions: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadAvailabilityOptions>(
      fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS
    ),
    mergeMap((action: fromActions.LoadAvailabilityOptions) =>
      this.dealerAdminService.getAvailabilityOptions('service').pipe(
        switchMap((data: IResponse<IAvailabilityOptions>) => toData(data)),
        map((availabilityOptions: IAvailabilityOptions) => new fromActions.LoadAvailabilityOptionsSuccess({availabilityOptions})),
        catchError(err => of(new fromActions.LoadAvailabilityOptionsFail({error: err})))
      )
    )
  ));

  
  loadAvailability: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadAvailability>(
      fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY
    ),
    withLatestFrom(this.serviceAppointmentState.select(fromSelectors.selectServiceOpcodes),
    this.serviceAppointmentState.select(fromSelectors.selectRecallCodes)),
    switchMap(([action, opcodes, recallCodes]: [fromActions.LoadAvailability, string[], string[]]) =>
      this.dealerAdminService.getAvailability('service', action.payload, opcodes, recallCodes).pipe(
        switchMap((data: IResponse<IAvailability>) => toData(data, 201)),
        map((availability: IAvailability) => new fromActions.LoadAvailabilitySuccess({availability, loadPayload: action.payload})),
        catchError(err => of(new fromActions.LoadAvailabilityFail({error: err})))
      )
    )
  ));
  
  loadStateCities:Observable<Action>= createEffect(() =>this.actions$.pipe(
    ofType<fromActions.LoadStatesAndCities>(
      fromActions.ServiceAppointmentActionTypes.LOAD_STATES_CITIES
    ),
    switchMap((action: fromActions.LoadStatesAndCities)  => {
      const payload = action instanceof fromActions.LoadStatesAndCities ? action.payload.zipPinCode : '';
      return this.catalogService.getStateCitiesForZipcode(payload).pipe(
        switchMap((data: IResponse<any>) => toData(data,201)),
        map((data: any) => new fromActions.LoadStatesAndCitiesSuccess({data:data.zipCodeResponse})),
        catchError(err => of(new fromActions.LoadAvailabilityOptionsFail({error: err})))
      );
    }
    )
  ));

  
  loadRecommendedService: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadRecommendedService>(
      fromActions.ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE
    ),
    switchMap((action: fromActions.LoadRecommendedService) => {
        const payload = action instanceof fromActions.LoadRecommendedService ? action.payload : undefined;
        return this.catalogService.getRecommendedService(payload.opcodeRequest,payload.engineDetail,payload.isLexus).pipe(
          switchMap((factoryServices: IResponse<factoryRecommendedServices[]>) => toData(factoryServices, 201)),
          map((factoryService: factoryRecommendedServices[]) => new fromActions.LoadRecommendedServiceSuccess({data:factoryService})),
          catchError(err => of(new fromActions.LoadRecommendedServiceFail({error: err})))
        );
      }
    )
  ));
  constructor(
    private readonly actions$: Actions,
    private readonly dealerAdminService: DealerAdminService,
    private readonly userVehicleService: UserVehicleService,
    private readonly appointmentService: AppointmentService,
    private readonly catalogService: CatalogService,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
  ) {
  }
}
