import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import * as fromActions from './sales-appointment.actions';
import * as fromAdapter from './sales-appointment.adapter';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { SalesAppointmentState, SeriesDetailsForYear } from './sales-appointment.reducer';
import { selectAllVehiclesFromInventory, stateDetails } from './sales-appointment.selectors';
import { Injectable } from '@angular/core';
import { VehicleService } from '../../shared/services/vehicle.service';
import { VehicleColor } from '../../shared/models/sales-appointments/vehicle-color';
import {  IResponse, IAvailabilityOptions, IAvailability, ITestDriveVehicleInventory, IChecklist } from '@signal/asp-data-commons';
import { DealerAdminService } from '../../shared/services/asp/dealer-admin.service';
import {toData} from '../app.adapter';
import { AlertService } from '../../shared/services/alert.service';
import { dealerCd, GLOBAL_CONSTANT } from '../../shared/services/util.service';
import { ChecklistService } from '../../shared/services/checklist.service';

@Injectable()
export class SalesAppointmentEffects {
    
    createAppointment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.CreateSalesAppointment>(
            fromActions.SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT
        ),
        withLatestFrom(this.store$.select(stateDetails), this.store$.select(selectAllVehiclesFromInventory)),
        switchMap(([action, state, allTestDriveVehicles]: [fromActions.CreateSalesAppointment, SalesAppointmentState, ITestDriveVehicleInventory[]]) =>{
            return this.appointmentService.create(fromAdapter.createSalesAppointment(state,action.payload, allTestDriveVehicles)).pipe(
                map((appointmentModel) => new fromActions.CreateSalesAppointmentSuccess(
                    { appointmentModel: appointmentModel.data })),
                catchError(err => {
                    this.alertService.openDialog(GLOBAL_CONSTANT.errorMessage); 
                    return of(new fromActions.CreateSalesAppointmentFail({ error: err }))
                })
            )
            })
    ));

    
    getEditData: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.GetEditData>(
            fromActions.SalesAppointmentActionTypes.GET_EDIT_DATA
        ),
        mergeMap((action: fromActions.GetEditData) =>
            this.appointmentService.get(action.payload.appointmentType, action.payload.appointmentId).pipe(
                map((appointmentModel) => new fromActions.GetEditDataSuccess({ appointmentModel: appointmentModel.data })),
                catchError(err => of(new fromActions.GetEditDataFail({ error: err })))
            ))));

    
    editAppointment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.EditSalesAppointment>(
            fromActions.SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT
        ),
        withLatestFrom(this.store$.select(stateDetails), this.store$.select(selectAllVehiclesFromInventory)),
        switchMap(([action, state, allTestDriveVehicles]: [fromActions.EditSalesAppointment, SalesAppointmentState, ITestDriveVehicleInventory[]]) =>
            this.appointmentService.update(fromAdapter.updateSalesAppointment(state,action.payload, allTestDriveVehicles)).pipe(
                map((appointmentModel) => new fromActions.EditSalesAppointmentSuccess(
                    { appointmentModel: appointmentModel.data })),
                catchError(err =>{
                    this.alertService.openDialog(GLOBAL_CONSTANT.errorMessage); 
                    return of(new fromActions.EditSalesAppointmentFail({ error: err }))
                })
            ))
    ));

    
    loadSeriesAndTrim: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadSeriesAndTrim>(
            fromActions.SalesAppointmentActionTypes.LOAD_SERIES_TRIM
        ),
        mergeMap((action: fromActions.LoadSeriesAndTrim) =>
            this.vehicleService.getSeriesData('TOY').pipe(
                map(value => fromAdapter.getSeriesAndTrim(value)),
                map((seriesDetails: SeriesDetailsForYear[]) => new fromActions.LoadSeriesAndTrimSuccess({ seriesDetails })),
                catchError(err => of(new fromActions.LoadSeriesAndTrimFail({ error: err })))
            )
        )
    ));

    
    loadInventoryVehicle: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadInventoryVehicle>(
            fromActions.SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE
        ),
        mergeMap((action: fromActions.LoadInventoryVehicle) =>
            this.vehicleService.getInventoryVehicles().pipe(
                switchMap((vehicles: IResponse<any>) => toData(vehicles)),
                map((vehicleDetails) => new fromActions.LoadInventoryVehicleSuccess({ vehicleDetails })),
                catchError(err => of(new fromActions.LoadInventoryVehicleFail({ error: err })))
            )
        )
    ));

    
    loadExteriorColors: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadExteriorColors>(
            fromActions.SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS
        ),
        mergeMap((action: fromActions.LoadExteriorColors) =>
            this.vehicleService.getVehicleColors('TOY', action.payload.year, action.payload.model, action.payload.trim).pipe(
                map(value => fromAdapter.getExteriorColors(value)),
                map((exteriorColors: VehicleColor[]) => new fromActions.LoadExteriorColorsSuccess({ exteriorColors })),
                catchError(err => of(new fromActions.LoadExteriorColorsFail({ error: err })))
            )
        )
    ));

    
    loadAvailabilityOptions: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadAvailabilityOptions>(
            fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS
        ),
        mergeMap((action: fromActions.LoadAvailabilityOptions) =>
            this.dealerAdminService.getAvailabilityOptions('sales', action.payload.appointmentType).pipe(
                switchMap((data: IResponse<IAvailabilityOptions>) => toData(data)),
                map((availabilityOptions: IAvailabilityOptions) => new fromActions.LoadAvailabilityOptionsSuccess({ availabilityOptions })),
                catchError(err => of(new fromActions.LoadAvailabilityOptionsFail({ error: err })))
            )
        )
    ));

    
    loadAvailability: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadAvailability>(
            fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY
        ),
        mergeMap((action: fromActions.LoadAvailability) =>
            this.dealerAdminService.getAvailability('sales', action.payload).pipe(
                switchMap((data: IResponse<IAvailability>) => toData(data, 201)),
                map((availability: IAvailability) => 
                    new fromActions.LoadAvailabilitySuccess({ availability, loadPayload: action.payload })),
                catchError(err => of(new fromActions.LoadAvailabilityFail({ error: err })))
            )
        )
    ));
    
    loadTestDriveVinVehicle: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadTestDriveVinVehicle>(
            fromActions.SalesAppointmentActionTypes.LOAD_TESTDRIVE_VIN_VEHICLE
        ),
        mergeMap((action: fromActions.LoadTestDriveVinVehicle) =>
            this.vehicleService.getVehicleUsingVinFromSource(action.payload.dealerCode, action.payload.vehicle).pipe(
                switchMap((data: IResponse<any>) => toData(data, 201)),
                map((vehicle: any) => new fromActions.LoadTestDriveVinVehiclecSuccess({ data:vehicle[0] })),
                catchError(err => of(new fromActions.LoadTestDriveVinVehiclecFailure({ error: err })))
            )
        )
    ));

    
    loadAllChecklist: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<fromActions.LoadAllChecklist>(
      fromActions.SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST
    ),
    mergeMap((action: fromActions.LoadAllChecklist) =>
      this.checklistService.getAllChecklist().pipe(
        map((checklistDetails: IChecklist[]) => new fromActions.LoadAllChecklistSuccess({ checklistDetails })),
        catchError(err => of(new fromActions.LoadAllChecklistFailure({ error: err })))
      )
    )
  ));

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<SalesAppointmentState>,
        private readonly appointmentService: AppointmentService,
        private readonly vehicleService: VehicleService,
        private readonly dealerAdminService: DealerAdminService,
        private readonly alertService: AlertService,
        private readonly checklistService: ChecklistService
    ) {
    }
}
