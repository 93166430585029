import { AuthService } from './../../services/auth.service';
import { Component, forwardRef, Inject, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DashboardState } from '../../../store/dashboard/dashboard.reducer';
import * as fromDashboardSelectors from '../../../store/dashboard/dashboard.selectors';
import * as util from '../../../shared/services/util.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'asp-trade-in-info',
  templateUrl: './trade-in-info.component.html',
  styleUrls: ['./trade-in-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TradeInInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TradeInInfoComponent),
      multi: true
    }
  ]
})
export class TradeInInfoComponent implements ControlValueAccessor, OnDestroy {
  vehicles = [];
  tradeInForm: UntypedFormGroup;
  signedIn: boolean;
  showOther = true;
  selectedGroup = 1;
  @Input() from: string;
  formChangeSub: Subscription;
  tradeInVehicleSub : Subscription;
  validatedFields = ['make', 'model', 'year'];
  setDefaultImg = util.setDefaultImg;
  maxManualTradeInVehicle = 3;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dashboardState: Store<DashboardState>,
    @Inject(SESSION_STORAGE) private readonly storage: WebStorageService,
    private readonly translate : TranslateService,
    private readonly authService: AuthService
  ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.tradeInForm = this.formBuilder.group({
      tradeInCheckbox: [false],
      vehicle: this.formBuilder.array([
        this.formBuilder.group({
          year: [null],
          make: [null],
          model: [null],
          vin: [null],
          mileage: [null],
          trim: [null],
          isOwnVehicle: [false]
        })
      ]),
      title: [''],
      trepresentativeInfo: [''],
      dealerComments: [''],
      tradeInComments: [''],
      internalComments: [''],
      additionalComments: ['']
    });
    this.getVehicleFromGarage();
    this.formChangeSub = this.tradeInForm.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  getVehicleImageUrl(url){
    const imageUrl = util.getCarImageAbsoluteURL(url)
    return imageUrl.includes('Sedan_Generic_base_default_34_f_d_490x280.png') ? util.assetUrl('images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png') : imageUrl;
  }

  onChange = (value) => { };
  onTouched = () => { };

  get value(): any {
    return this.tradeInForm.value;
  }

  set value(value: any) {
    this.tradeInForm.patchValue(value);
    this.setTradeInVehicles(value.vehicle);
    this.onChange(value);
    this.onTouched();
  }

  get vehicleArray() {
    return this.tradeInForm.get('vehicle') as UntypedFormArray;
  }

  setTradeInVehicles(value) {
    value.forEach((element, i) => {
      if (!this.vehicleArray.controls[i]) {
        this.vehicleArray.push(new UntypedFormGroup({
          year: new UntypedFormControl(element && element.year ? element.year : ''),
          make: new UntypedFormControl(element && element.make ? element.make : ''),
          model: new UntypedFormControl(element && element.model ? element.model : ''),
          trim: new UntypedFormControl(element && element.trim ? element.trim : ''),
          vin: new UntypedFormControl(element && element.vin ? element.vin : ''),
          mileage: new UntypedFormControl(element && element.mileage ? +element.mileage : 0),
          isOwnVehicle: new UntypedFormControl(element && element.isOwnVehicle ? element.isOwnVehicle : false)
        }));
      } else {
        this.vehicleArray.controls[i].patchValue(new UntypedFormGroup({
          year: new UntypedFormControl(element && element.year ? element.year : ''),
          make: new UntypedFormControl(element && element.make ? element.make : ''),
          model: new UntypedFormControl(element && element.model ? element.model : ''),
          trim: new UntypedFormControl(element && element.trim ? element.trim : ''),
          vin: new UntypedFormControl(element && element.vin ? element.vin : ''),
          mileage: new UntypedFormControl(element && element.mileage ? +element.mileage : 0),
          isOwnVehicle: new UntypedFormControl(element && element.isOwnVehicle ? element.isOwnVehicle : false)
        }));
      }
    });
  }

  get f() { return this.tradeInForm.controls; }

  registerOnChange = (fn: any) => {
    this.onChange = fn;
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
      this.setVehicleOnUpdate(value);
    }

    if (value === null) {
      this.tradeInForm.reset();
    }
  }

  setVehicleOnUpdate(value) {
    if (this.signedIn && value.tradeInCheckbox &&
      value.vehicle.length) {
      value.vehicle.forEach(vehicleData => {
        const index = this.vehicles.findIndex(x => x.vin === vehicleData.vin);
        if (index !== -1) {
          const event = { checked: true };
          this.setVehicle(event, vehicleData, index);
        }
      })
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    if(this.vehicleArray.length) {
      this.vehicleArray.controls.forEach((a,i) => {
        if(this.tradeInForm.value.tradeInCheckbox) {
          this.setVehicleValidator(i);
        }
        else {
          this.removeVehicleValidator(i);
        }
      })
    }
    return this.tradeInForm.valid ? null : { tradeIn: { valid: false } };
  }

  login() {
    this.authService.loginB2c();
  }

  setVehicle(event, vehicle, index) {
    delete vehicle.isChecked;
    const tradeInVehicles = this.tradeInForm.value.vehicle;
    const tradeInVehiclesLength = tradeInVehicles.length;
    const vehicleIndex = tradeInVehicles.findIndex(x => x.vin === vehicle.vin);
    if (event.checked) {
      vehicle = { ...vehicle, isOwnVehicle: true }
      this.vehicles[index].isChecked = true;
      if (vehicleIndex === -1) {
        if (tradeInVehiclesLength === 1 && (!this.vehicleArray.controls[0].value.isOwnVehicle && this.isVehicleControlHasValue())) {
          this.vehicleArray.controls[0].patchValue(vehicle);
        }
        else {
          this.vehicleArray.push(this.formBuilder.group({ ...vehicle }));
        }
      }
      else {
        this.vehicleArray.controls[vehicleIndex].patchValue(vehicle);
      }
    } else {
      this.vehicles[index].isChecked = false;
      if (vehicleIndex !== -1) {
        if (tradeInVehiclesLength === 1) {
          const vehicleObj = {
            year: null,
            make: null,
            model: null,
            vin: null,
            mileage: null,
            trim: null,
            isOwnVehicle: false
          };
          this.vehicleArray.controls[0].patchValue(vehicleObj);
        }
        else {
          this.vehicleArray.removeAt(vehicleIndex);
        }
      }
    }
  }

  removeVehicleValidator(index: number) {
    const vehicle = this.vehicleArray.controls[index] as UntypedFormGroup;
    this.validatedFields.forEach(field => vehicle.controls[field].setErrors(null, { emitEvent: false }));
  }

  setVehicleValidator(index: number) {
    const vehicle = this.vehicleArray.controls[index] as UntypedFormGroup;
    this.validatedFields.forEach(fields => {
      if (!vehicle.controls[fields].value) {
        vehicle.controls[fields].setErrors({ required: `${fields} ${this.translate.instant('service.isRequired')}` });
      }
    });
  }

  getVehicleFromGarage() {
    this.tradeInVehicleSub = this.dashboardState.select(fromDashboardSelectors.selectUserVehicles)
      .subscribe(result => {
        this.vehicles = [];
        result.forEach(e => {
          this.vehicles.push({
            carImage: util.getCarImageAbsoluteURL(e.imageUrl),
            nickName: e.description,
            make: e.make,
            model: e.model,
            year: e.year,
            vin: e.vin,
            trim: e.trim || '',
            mileage: e.mileage || 0,
            isChecked: false
          });
        });
        this.setVehicleOnUpdate(this.tradeInForm.value);
      });
  }

  onAddVehicle() {
    this.vehicleArray.push(this.formBuilder.group({
      year: [null],
      make: [null],
      model: [null],
      vin: [null],
      mileage: [null],
      trim: [null],
      isOwnVehicle: [false]
    }));
  }

  onDeleteVehicle(index: number) {
    this.vehicleArray.removeAt(index);
  }

  manualEntryVehicles() {
    return this.vehicleArray.value.filter(x=> !x.isOwnVehicle);
  }

  isVehicleControlHasValue() {
    return (!this.vehicleArray.controls[0].value.make && !this.vehicleArray.controls[0].value.year && !this.vehicleArray.controls[0].value.model 
      && !this.vehicleArray.controls[0].value.vin && !this.vehicleArray.controls[0].value.trim && !+this.vehicleArray.controls[0].value.mileage)
  }

  isFirstmanualVehicle(index : number) {
    const manualEntryVehicles = this.manualEntryVehicles();
    return this.vehicleArray.value[index] === manualEntryVehicles[0];
  }

  ngOnDestroy() {
    this.formChangeSub?.unsubscribe();
    this.tradeInVehicleSub?.unsubscribe();
  }
}
