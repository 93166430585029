import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MessageInfoComponent } from '../message-info/message-info.component';
import { FactoryPrintService } from './factory-print.service';
import { EngineDetail, IDCSVehicle, IOpcodeRequest } from '@signal/asp-data-commons';
import {  countryCd } from '../../../shared/services/util.service';
import { LoadRecommendedService } from '../../../store/service-appointment/service-appointment.actions';
import { ServiceAppointmentState } from '../../../store/service-appointment/service-appointment.reducer';
import { Store } from '@ngrx/store';
import { selectFactoryRecommendedService } from '../../../store/service-appointment/service-appointment.selectors';
import { DealerState } from '../../../store/dealer/dealer.reducer';
import { selectDealerCode } from '../../../store/dealer/dealer.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-factory-services-dialog',
  templateUrl: './factory-services-dialog.component.html',
  styleUrls: ['./factory-services-dialog.component.scss']
})
export class FactoryServicesDialogComponent implements OnInit {
  sortedFactoryServices=[];
  additionalSortedFactoryServices = [];
  servItemId=0;
  servItemNotes=[];
  drivingImpactsTitle='';
  recommendedTitle='';
  showNotes:boolean;
  dealer:string = "toyota";
  bannerImage: any = [];
  selectedEngine:string;
  engineList:EngineDetail[]=[];
  loading:boolean = false;
  vehicleBrand:string = "toyota";
  showData:boolean = false;
  engineTypeLabelText:string = "EngineType";
  engineTypeMandatoryText:string = '';
  constructor(
    public readonly dialogRef: MatDialogRef<FactoryServicesDialogComponent>,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dialog: MatDialog,
    private readonly factoryPrintService: FactoryPrintService,
    private readonly dealerState: Store<DealerState>,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {
      this.dealerState.select(selectDealerCode).subscribe(dealercode => {
        this.dealer = 'toyota';
        if(dealercode?.toString().startsWith('6')){
          this.dealer = 'lexus';
        }
      });
    

  }

  ngOnInit(): void {
    this.loading = false;
    const vehicleDetails: IDCSVehicle=this.data.factoryServices[0].vehicleDetails;
    if(this.data.engineDetails && this.data.engineDetails.length > 1 && vehicleDetails.make?.toLowerCase() !== 'lexus'){
      this.selectedEngine = '';
      this.showData = false;
      this.engineTypeLabelText = `${this.translate.instant('recommendedService.engineType')}<span class='asterick'></span>`;
      this.engineTypeMandatoryText = this.translate.instant('recommendedService.selectEngineType');
    }else{
      this.selectedEngine = `${this.data.engineDetails[0]?.engine},${this.data.engineDetails[0]?.transmission},${this.data.engineDetails[0]?.driveType}`;
      this.showData = true;
      this.engineTypeLabelText = this.translate.instant('recommendedService.engineType');
      this.engineTypeMandatoryText = '';
    }
    const mileages = this.data.mileages[0];
    this.vehicleBrand = vehicleDetails.make?.toLowerCase();
    if(vehicleDetails.make?.toLowerCase() === 'lexus'){
      this.drivingImpactsTitle = this.translate.instant('recommendedService.specialDrivingConditions');
      this.recommendedTitle = `${this.translate.instant('recommendedService.alsoRecommended')} `;
      this.showNotes = false;
    }
    else{
      this.drivingImpactsTitle = this.translate.instant('recommendedService.whereAndHowYouDrivingImpactsYourServicingNeeds')
      this.recommendedTitle = '';
      this.showNotes = true;
    }
    this.loadFactoryServiceData(vehicleDetails,mileages,this.data.factoryServices[0]);
    for(const engineDetail of this.data.engineDetails){
      this.engineList.push({...engineDetail,name:`${engineDetail.engine},${engineDetail.transmission},${engineDetail.driveType}`});
    }
  }

  close() {
    this.dialogRef.close();
  }
  sortFactoryServiceData(factoryServicesDetails,key:string){
    const sortedFactoryService = (key==="additionalfactoryservice") ? this.additionalSortedFactoryServices: this.sortedFactoryServices;
    
    for(const task of factoryServicesDetails){
      if(sortedFactoryService && sortedFactoryService.length>0
        && sortedFactoryService.find(service=>service.id===task.opCondid)){
          const servItem ={
            name:task.servItemDesc,
            notesId:0,
            notesName:''
          }
          if(task.servItemNotes ){
            const noteDetails = this.servItemNotes.find(note=>(note.name===task.servItemNotes));
            if(noteDetails===undefined){
            servItem.notesId = ++this.servItemId;
            servItem.notesName = task.servItemNotes;
            this.servItemNotes.push({id:servItem.notesId,name:task.servItemNotes});
            }
            else{
              servItem.notesId = noteDetails.id;
              servItem.notesName = noteDetails.name;
            }
          }
      sortedFactoryService.find(service=>service.id===task.opCondid).
      factoryServices.push(servItem);

      }else{
        const displayName = task.opCondid==="1" ? this.translate.instant('recommendedService.recommendedMaintenence') :(this.recommendedTitle + task.operCondDesc);
        const servItem ={
          name:task.servItemDesc,
          notesId:0,
          notesName:''
        }
        const operCondNote={id:0,name:''}
        if(task.servItemNotes ){
          const noteDetails = this.servItemNotes.find(note=>(note.name===task.servItemNotes));
          if(noteDetails===undefined){
            servItem.notesId = ++this.servItemId;
            servItem.notesName = task.servItemNotes;
            this.servItemNotes.push({id:servItem.notesId,name:task.servItemNotes});
          }
          else{
            servItem.notesId = noteDetails.id;
            servItem.notesName = noteDetails.name;
          }

        }
        if(task.operCondNotes){
          operCondNote.id = ++this.servItemId;
          operCondNote.name = task.operCondNotes;
          this.servItemNotes.push({id:operCondNote.id,name:operCondNote.name});
        }
        const sortValue={
          id:task.opCondid,
          notesId:operCondNote.id,
          notesName:operCondNote.name,
          displayName:displayName,
          factoryServices:[servItem]
        }
        if(key==="additionalfactoryservice")  
        {
          this.additionalSortedFactoryServices.push(sortValue);
        }
        else{
          this.sortedFactoryServices.push(sortValue);
        }
      }
    }
  
    
  }
  openDisclaimer(item){
    if(item.notesId){
      this.dialog.open(MessageInfoComponent, {
        width: '540px',
        panelClass:"message-container",
        data: {
          message:item.notesName,
          title: this.translate.instant('recommendedService.disclaimer')
        }
      });
    }
    
  }
  printPDF(){
    if(this.dealer?.toLowerCase() === 'lexus' ){
      !countryCd.toUpperCase().includes('US') && this.vehicleBrand === 'lexus' ? this.factoryPrintService.printPDF(true) : this.printLexus();
    }
    else{
      this.factoryPrintService.printPDF(false);
    }
  }
  changeRecommendedServiceDetails(engine){
    this.loading = true;
    const opcodeRequest: IOpcodeRequest = {
      mileage:this.data.mileages[0],
      model: this.data.factoryServices[0].vehicleDetails.model,
      year: this.data.factoryServices[0].vehicleDetails.year,
      make: this.data.factoryServices[0].vehicleDetails.make ? this.data.factoryServices[0].vehicleDetails.make.toUpperCase() : undefined,
    };
    this.serviceAppointmentState.dispatch(new LoadRecommendedService({opcodeRequest, engineDetail:engine,isLexus:false}));
    this.serviceAppointmentState.select(selectFactoryRecommendedService).subscribe(response => {
      if(response && response.length>0){
        this.sortedFactoryServices = [];
          const vehicleDetails: IDCSVehicle=response[0].vehicleDetails;
          const mileages = this.data.mileages[0];
          this.loadFactoryServiceData(vehicleDetails,mileages,response[0]);
          this.loading = false;
          this.showData = true;
      }
    });
  }
  loadFactoryServiceData(vehicleDetails,mileages,factoryService){
    this.sortFactoryServiceData(factoryService.factoryServicesDetails,'factoryservice');
    !countryCd.toUpperCase().includes('US') && this.vehicleBrand === 'lexus' ? 
      this.factoryPrintService.makePdfDataForLexusVehicleDetails(vehicleDetails,mileages) :
      this.factoryPrintService.makePdfDataForVehicleDetails(vehicleDetails,mileages);
      this.factoryPrintService.makePdfDataForFactoryServices(this.sortedFactoryServices,this.servItemNotes);
  }
  printLexus(){
    const opcodeRequest: IOpcodeRequest = {
      mileage:this.data.mileages[0],
      model: this.data.factoryServices[0].vehicleDetails.model,
      year: this.data.factoryServices[0].vehicleDetails.year,
      make: this.data.factoryServices[0].vehicleDetails.make ? this.data.factoryServices[0].vehicleDetails.make.toUpperCase() : undefined,
    };
    const engineDetail:EngineDetail = {
      ...this.data.engineDetails[0]
    }
    this.serviceAppointmentState.dispatch(new LoadRecommendedService({opcodeRequest, engineDetail:engineDetail,isLexus:true}));
    this.serviceAppointmentState.select(selectFactoryRecommendedService).subscribe(response => {
      if(response && response.length> 0){
        const vehicleDetails: IDCSVehicle=response[0].vehicleDetails;
        const mileages = this.data.mileages[0];
          this.factoryPrintService.makePdfDataForLexusVehicleDetails(vehicleDetails,mileages);
          const additionalFactoryDetails =[response[0],this.data.factoryServices[0],response[1]];
          for(let i=0;i<3;i++){
            this.additionalSortedFactoryServices=[];
            this.sortFactoryServiceData(additionalFactoryDetails[i].factoryServicesDetails,'additionalfactoryservice');
            this.factoryPrintService.makePdfDataForLexusFactoryServices(this.additionalSortedFactoryServices,i,mileages);
          }
          this.factoryPrintService.setDataForLexusAllMileages();
          this.factoryPrintService.printPDF(true);
      }
     
    });
  }
}
