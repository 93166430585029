import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../../../store/sales-appointment/sales-appointment.selectors';
import { Observable } from 'rxjs';
import { AppointmentType, Department, IAvailability, IAvailabilityOptions } from '@signal/asp-data-commons';
import { DateTime } from 'luxon';
import { SalesAppointmentState } from '../../../store/sales-appointment/sales-appointment.reducer';
import { SalesDataShareService } from '../../services/sales-data-share.service';
import _ from 'lodash';
import { SubscriptionList } from '../../../shared/models/asp.types';
import { unsubscribeSubscriptions } from '../../../shared/services/util.service';
import * as fromActions from '../../../store/sales-appointment/sales-appointment.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sales-appointment-time',
  templateUrl: './sales-appointment-time.component.html',
  styleUrls: ['./sales-appointment-time.component.scss']
})
export class SalesAppointmentTimeComponent implements OnInit, OnDestroy {
  @Input() transport;
  @Input() checklistDuration: { hr: number; min: number; };
  @Input() apptType;
  @Input() lockSalesConsultant: string;
  @Input() isEditMode: boolean;
  @Input() appointmentId: string;
  dateFormat : string = 'yyyy-MM-dd';

  department: string = Department.SALES;
  step: UntypedFormGroup;
  private readonly availability: UntypedFormControl;
  availabilityOptions$: Observable<IAvailabilityOptions>;
  availabilityOptionsLoading$: Observable<boolean>;
  availability$: Observable<IAvailability>;
  availabilityLoading$: Observable<boolean>;
  lastValue: any;
  subs: SubscriptionList = {};
  languageId='';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly salesDataShareService: SalesDataShareService,
  ) { }

  ngOnInit(): void {
    this.ready();
    this.setupStore();
  }

  ready() {
    this.step = this.formBuilder.group({
      availability: this.availability,
      timeSlotValidator: [false, Validators.required]
    });
    this.onValueChange();
  }

  setupStore() {
    this.availabilityOptions$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptions);
    this.availabilityOptionsLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptionsLoading);
    this.availability$ = this.salesAppointmentState.select(fromSelectors.getAvailability);
    this.availabilityLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityLoading);

    this.subs.availability = this.salesAppointmentState.select(fromSelectors.selectAppointmentAvailability).subscribe(val => {
      if (_.isEqual(this.lastValue, val)) {
        return;
      }
      this.languageId=val?.languageId;
      this.lastValue = val;
      this.step.get('availability').patchValue(val);
    })

  }

  onValueChange() {
    this.subs.valueChanges = this.step.get('availability').valueChanges.subscribe(val => {
      if (_.isEqual(this.lastValue, val)) {
        return;
      }
      this.lastValue = val;
      this.salesAppointmentState.dispatch(new fromActions.UpdatePatchSalesForm({ path: 'availability', value: val }));
    })
  }

  onMonthChange($event: DateTime) {
    // this.salesDataShareService.onMonthChange($event);

    this.subs.salesAdvisor = this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
      .pipe(take(1))
      .subscribe((data) => {
       const date = $event.toFormat(this.dateFormat);
       const payload = {selectDateAndSlot: true, date, advisorId: data.advisorId, transport:this.transport ,
         appointmentType: this.apptType, appointmentId: this.appointmentId };
       if(this.apptType === AppointmentType.DELIVERY) {
         payload['checklistDuration'] = this.checklistDuration;
       }
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(payload));
      });

  }

  setValidatorsForTimeSlot(event) {
    if (event) {
      this.step.controls.timeSlotValidator.setErrors({ error: 'Invalid timeslot' });
      this.step.controls.timeSlotValidator.setValidators(Validators.required);
    } else {
      this.step.controls.timeSlotValidator.clearValidators();
      this.step.controls.timeSlotValidator.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}

