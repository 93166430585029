import {UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Custom validator to do the OR condition validation in select vehicle form (Step 1)
 */
export function chooseVehicleValidator(): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors => {
    const isOwnVehicle = group.controls.isOwnVehicle;
    const isInputVinBased = group.controls.isInputVinBased;
    const vin = group.controls.vin;
    const make = group.controls.make;
    const otherMake = group.controls.otherMake;
    const year = group.controls.year;
    const model = group.controls.model;
    const mileage = group.controls.mileage;

    if (isOwnVehicle.value) {
      if(!vin.value) {
        vin.setErrors(null);
       return {required: 'welcome.vehicleIsRequired'};
      } else if (!+(mileage.value)) {
        return {required: 'welcome.mileageIsRequired'};
      } else {
        return null;
      }
    } else {
      if (isInputVinBased.value) {
        if (!vin.value) {
          vin.setErrors({required: 'welcome.vinIsRequired'});
        } else {
          if (!/^([a-zA-Z0-9]{17})$/.test(vin.value)) {
            vin.setErrors({pattern17DigitAndAlphaNumeric: 'welcome.vinShouldBe17DigitAndAlphanumeric'});
          } else if (!/^(.{17})$/.test(vin.value)) {
            vin.setErrors({pattern17Digit: 'welcome.vinShouldBe17Digit'});
          } else if (!/^([a-zA-Z0-9]*)$/.test(vin.value)) {
            vin.setErrors({patternAlphaNumeric: 'welcome.vinShouldBeAlphanumeric'});
          }
        }

      } else {
        if (!make.value) {
          make.setErrors({required: 'welcome.makeIsRequired'});
        }
        if (!otherMake.value && make.value === 'Other') {
          otherMake.setErrors({required: 'welcome.makeNameIsRequired'});
        } else {
          otherMake.setErrors(null);
        }
        if (!year.value) {
          year.setErrors({required: 'welcome.yearIsRequired'});
        }
        if (!model.value) {
          model.setErrors({required: 'welcome.modelIsRequired'});
        }
      }
      if (!+(mileage.value)) {
        mileage.setErrors({required: 'welcome.mileageIsRequired'});
      }
    }

    return;
  };
}

/**
 * Select service validator
 */
export function getServiceRequestValidator(): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors => {
    const servicesSelected: any[] = group.value?.allServices?.filter((item) => item.selected).map((item) => item.id);
    const recommendedServicesSelected: any[] = group.value?.recommendedServices?.filter((item) => item.selected).map((item) => item.id);
    if (!(servicesSelected.length + recommendedServicesSelected.length)) {
      return {serviceRequired: 'welcome.atLeastOneServiceIsRequired'};
    } else {
      return null;
    }
  };
}
