import {ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehicleDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VehicleDetailsComponent),
      multi: true
    }
  ]
})
export class VehicleDetailsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() vehicleDetails;
  @Input() isEditMode;
  @Input() vin;
  @Input() withoutFleetVehicle;

  vehicle: UntypedFormGroup;
  otherVehicles: UntypedFormArray;
  subscription: Subscription;
  disabled: boolean;
  displayMarketingTitle:String = '';
  displayBool: boolean = true;

  constructor(private readonly formBuilder: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate : TranslateService
    ) {
    this.vehicle = this.formBuilder.group({
      year: [''],
      model: [''],
      trim: [''],
      exteriorColor: [''],
      vin:[''],
      imageUrl:['']
    });

    this.subscription = this.vehicle.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
    });
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
    if(this.vehicleDetails.length>0 && this.displayBool){
      this.displayfn();
    }
  }

  displayfn(){
    this.displayBool = false;
    const {trim, model, year, vin, imageUrl, exteriorColor} = this.vehicle.value;
    const temp = this.getMarketingTitle(this.vehicleDetails,year,model,vin);
    if(temp && temp.length > 0){
      this.displayMarketingTitle = (temp[0].marketingTitle)?temp[0].marketingTitle:this.translate.instant('testDrive.anyTrim');
    }
  }
  
  getMarketingTitle(vehicles,year,model,vin){
    if(vehicles.length){
      const allYearVehicles = vehicles.filter(obj => obj.year == year.toString());
      const allModelVehicles = allYearVehicles.map(obj => obj.series_List).flat().filter(obj => obj.model === model);
      const allVehicles = allModelVehicles.map(obj => Object.values(obj.trims));
      const vehicle = allVehicles.flat(2).filter(obj => obj.vin === vin);
      return vehicle;
    }else{
      return []
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get f() { return this.vehicle.controls; }

  compareWith(o1: any, o2: any) {
    return o1 === o2;
  }

  get value(): any {
    return this.vehicle.value;
  }

  set value(value: any) {
    if (this.disabled) { return; }
    let {trim, model, year, vin, imageUrl, exteriorColor} = value;
    /* If imageUrl, exteriorColor is missing */
    // if(!imageUrl){
    //   imageUrl='';
    // }
    imageUrl = (imageUrl)?imageUrl:'';
    exteriorColor = (exteriorColor)?exteriorColor:'';
    this.vehicle.setValue({trim, model, year, vin, imageUrl, exteriorColor});
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.vehicle.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.vehicle.valid ? null : { vehicle: { valid: false } };
  }

}
