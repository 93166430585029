import {SharedModule} from './../shared/shared.module';
import {WelcomeComponent} from './welcome.component';
import {NgModule} from '@angular/core';
import {WelcomeRoutingModule} from './welcome-routing-module';

@NgModule({
    declarations: [WelcomeComponent],
    imports: [
        SharedModule,
        WelcomeRoutingModule
    ],
    providers: []
})
export class WelcomeModule { }
