import { Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { UserVehicleService } from '../shared/services/user-vehicle.service';
import { dealerAppointmentDetails } from '../../assets/sampledata/appt-dealer-details';
import {
  ICustomerForm,
  IServiceRequestForm,
  IVehicleForm,
  ServiceAppointmentState
} from '../store/service-appointment/service-appointment.reducer';
import { vehicleFormToVehicleProjector ,getTotalCostAndLabourHrs } from '../store/service-appointment/service-appointment.adapter';
import { AppointmentBookingSource, AppointmentType, IAddress, ICustomer, IServiceAppointment, IServiceRequest } from '@signal/asp-data-commons';
import { DealerState } from '../store/dealer/dealer.reducer';
import { Store } from '@ngrx/store';
import { User, Profile } from 'oidc-client';
import {Duration} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AppointmentFormService {

  /* Service main form - Accessible from outside*/
  appointmentMainForm: UntypedFormGroup;
  public validateServices: Subject<void> = new Subject();
  /* DetailMap for showing summary */
  detailMap: { [key: string]: string };
  // JSON to store the session details before login
  sessionValue = {};
  dealerDetails = dealerAppointmentDetails;
  isEditMode = false;

  userInfo: User;
  private isOpcodeCompRentalEligible: Boolean[] = [];
  private costForCompEligible: string = "";
  private flatRateHrsForCompEligible: string = "";
  /* All 4 steps are sub form - Accessible only through the main form */
  private readonly selectServiceForm: UntypedFormGroup;
  private readonly selectVehicleForm: UntypedFormGroup;
  private readonly appointmentTimeForm: UntypedFormGroup;
  private readonly yourInformationForm: UntypedFormGroup;
  private _selectedMileage: number;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(SESSION_STORAGE) private readonly storage: WebStorageService,

    private readonly currencyPipe: CurrencyPipe,
    private readonly dealerState: Store<DealerState>,
    private readonly userVehicleService: UserVehicleService
  ) {

  }
  public get selectedMileage() {
    return this._selectedMileage;
  }
  public set setSelectedMileage(mileage: number) {
    this._selectedMileage = mileage;
  }

  getMainFormDataAsCreateReq(serviceAppointmentFeature: ServiceAppointmentState, profile?: any): IServiceAppointment {
    const appointmentForm = serviceAppointmentFeature.appointmentForm;
    const availability = serviceAppointmentFeature.availability;
    const appointmentDetails = {} as IServiceAppointment;
    let email = '';
    if (profile) {
      email = profile.email;
    }
    else {
      email = appointmentForm.customerForm.email;
    }
    appointmentDetails.emailAddress = email;
    if(!appointmentDetails.emailAddress) {
      delete appointmentDetails.emailAddress;
    }
    appointmentDetails.appointmentType = AppointmentType.SERVICE;

    if (this.checkIfRecallServiceSelected(appointmentForm.serviceForm)) {
      appointmentDetails.recallCodes = appointmentForm.recallCodes;
    }

    appointmentDetails.languageId = appointmentForm.availabilityForm.availability.languageId;
    appointmentDetails.advisorId = appointmentForm.availabilityForm.availability.advisorId;

    appointmentDetails.appointmentStartDate = appointmentForm.availabilityForm.availability.appointmentStartDate;
    appointmentDetails.transportationTypeCode = appointmentForm.availabilityForm.transport;
    appointmentDetails.appointmentComments = appointmentForm.customerForm.appointmentComments;
    if (appointmentDetails.transportationTypeCode === 'REMOTE' || appointmentDetails.transportationTypeCode === 'PICKUP' ||
    appointmentDetails.transportationTypeCode === 'DELIVERY') {
      appointmentDetails.remotePickupTimeSlotId = appointmentForm.availabilityForm.availability.timeSlotId;

      for (let i = 0; i < availability.data.availableDates.length; i++) {
        if (availability.data.availableDates[i].date === appointmentForm.availabilityForm.availability.appointmentStartDate) {
          for (let j = 0; j < availability.data.availableDates[i].timeSlots.length; j++) {
            if (availability.data.availableDates[i].timeSlots[j].timeSlotId === appointmentForm.availabilityForm.availability.timeSlotId) {
              if (availability.data.availableDates[i].timeSlots[j].remoteEstimatedCompletionTime) {
                switch (appointmentDetails.transportationTypeCode) {
                  case 'PICKUP':
                    {
                      appointmentDetails.remotePickupTimeSlotId = appointmentForm.availabilityForm.availability.timeSlotId;
                      appointmentDetails.timeSlotId = availability.data.availableDates[i].timeSlots[j].remoteAppointmentTimeSlotId;
                      break;
                    }
                  case 'DELIVERY':
                    {
                      appointmentDetails.timeSlotId = appointmentForm.availabilityForm.availability.timeSlotId;
                      appointmentDetails.remoteDropTimeSlotId = availability.data.availableDates[i].timeSlots[j].remoteDropTimeSlotId;
                      break;
                    }
                  case 'REMOTE':
                    {
                      appointmentDetails.remotePickupTimeSlotId = appointmentForm.availabilityForm.availability.timeSlotId;
                      appointmentDetails.timeSlotId = availability.data.availableDates[i].timeSlots[j].remoteAppointmentTimeSlotId;
                      appointmentDetails.remoteDropTimeSlotId = availability.data.availableDates[i].timeSlots[j].remoteDropTimeSlotId;
                      break;
                    }
                    default : break;
                }

            }
          }
        }
      }
    }
    }
    else {
      appointmentDetails.timeSlotId = appointmentForm.availabilityForm.availability.timeSlotId;
    }

    appointmentDetails.appointmentBookingSource = AppointmentBookingSource.ASP;

    appointmentDetails.vehicle = vehicleFormToVehicleProjector(appointmentForm.vehicleForm);
    appointmentDetails.serviceRequests = this.buildSelectedServicesInfo(appointmentForm.serviceForm);
    /* Is Appt Eligible for CompRental code */
    let opCodeRentalEligible = this.isOpcodeCompRentalEligible.find(compEligible => compEligible);
    if (!opCodeRentalEligible && appointmentDetails.serviceRequests && appointmentDetails.serviceRequests.length > 0) {
      const totalCostAndLabourHrs = getTotalCostAndLabourHrs();
      const flatRateHrsForCompEligibleDuration = this.flatRateHrsForCompEligible? Duration.fromObject({ minutes: (parseInt(this.flatRateHrsForCompEligible.toString()) * 60) }) : null;
      const totalLabourHrsDuration=Duration.fromObject({ minutes: (parseInt(totalCostAndLabourHrs.hrs.toString()) * 60) })
      opCodeRentalEligible = (this.costForCompEligible && totalCostAndLabourHrs.cost >= parseInt(this.costForCompEligible.toString())) || (flatRateHrsForCompEligibleDuration && totalLabourHrsDuration >= flatRateHrsForCompEligibleDuration) ;
    }
    appointmentDetails.dealershipApptComments = opCodeRentalEligible ? '| Eligible for Complimentary Rental - YES' : '';
    appointmentDetails.roundOffMileage = serviceAppointmentFeature?.serviceRequests?.data?.recommendationBasedOn?.roundedOffMileage;
    appointmentDetails.customer = this.getCustomerInfo(appointmentForm.customerForm);
    if (appointmentForm.customerForm.dropOffAddress.addressLine1 === '') {
      appointmentDetails.customer.isDropOffAddress = true;
    }
    else {
      appointmentDetails.customer.isDropOffAddress = false;
    }
    if (appointmentForm.customerForm.isRemoteLocation && appointmentForm.customerForm.pickUpAddress.addressLine1
      && appointmentForm.customerForm.dropOffAddress.addressLine1) {
      appointmentDetails.pickUpAddress = this.mapAddress(appointmentForm.customerForm.pickUpAddress);
      appointmentDetails.dropOffAddress = this.mapAddress(appointmentForm.customerForm.dropOffAddress);
    }

    return appointmentDetails;
  }

  checkIfRecallServiceSelected(serviceForm: IServiceRequestForm): boolean {
    return serviceForm.selectedServices.filter(t => t.details?.recommended?.isSSCRecall).length > 0;
  }

  getMainFormDataAsUpdateReq(serviceAppointmentFeature: ServiceAppointmentState, profile?: Profile): IServiceAppointment {
    const fullData = this.getMainFormDataAsCreateReq(serviceAppointmentFeature, profile);
    fullData.appointmentId = serviceAppointmentFeature.appointmentForm.editDetails.appointmentId;
    fullData.dealerCode = serviceAppointmentFeature.appointmentForm.editDetails.appointment.dealerCode;
    return fullData;
  }

  buildSelectedServicesInfo(serviceForm: IServiceRequestForm): IServiceRequest[] {
    this.isOpcodeCompRentalEligible = [];
    return serviceForm.selectedServices.map(t => {
      this.isOpcodeCompRentalEligible.push(t.details.isCompRentalEligible);
      const opCode=t.details?.isInternalCode ? t.details?.mapDmsCode?.opcode :t.opcode;
      const internalCode=t.details?.isInternalCode ? t.details?.opcode :"";
      const dmsApptOpcode = t.details?.isDmsApptOpcode ? t.details?.isDmsApptOpcode : false;
      return { opcode: opCode, comments: t.comments ,internalCode,subComplaints:t?.subComplaints, dmsApptOpcode: dmsApptOpcode} as IServiceRequest;
    });
  }

  getCustomerInfo(customerForm: ICustomerForm): ICustomer {
    const customer = {} as ICustomer;
    customer.firstName = customerForm.firstName;
    customer.lastName = customerForm.lastName;
    customer.emailAddress = customerForm.email;
    if(!customer.emailAddress) {
      delete customer.emailAddress;
    }
    customer.phoneNumber = customerForm.phoneNumber || '';
    if (customerForm.customerAddress?.addressLine1) {
      const address = {} as IAddress;
      address.addressLine1 = customerForm.customerAddress.addressLine1;
      address.addressLine2 = customerForm.customerAddress.addressLine2;
      address.city = customerForm.customerAddress.city;
      address.state = customerForm.customerAddress.state;
      address.zip = customerForm.customerAddress.zip;
      address.country = 'USA'; // Hard coded for phase 1
      customer.address = address;
    }
    return customer;
  }

  markStepAsTouched(step) {
    switch (step) {
      case 0:
        if (this.selectVehicleForm) {
          this.selectVehicleForm.markAllAsTouched();
        }
        return;
      case 1: {
        this.validateServices.next();
        return;
      }
      case 2:
        if (this.appointmentTimeForm) {
          this.appointmentTimeForm.markAllAsTouched();
        }
        return;
      case 3:
        if (this.yourInformationForm) {
          this.yourInformationForm.markAllAsTouched();
        }
        return;
      default:
        return false;
    }
  }

  private getSelectedVehicleSummary(vehicleForm: IVehicleForm) {
    const selectedVehicle = vehicleFormToVehicleProjector(vehicleForm);
    return `${selectedVehicle.year || ''} ${selectedVehicle.model} \n ${selectedVehicle.vin || ''}`;
  }
  private mapAddress(customerAddress) {
    const address = {} as IAddress;
    address.addressLine1 = customerAddress.addressLine1;
    address.addressLine2 = customerAddress.addressLine2;
    address.city = customerAddress.city;
    address.state = customerAddress.state;
    address.zip = customerAddress.zip;
    address.country = 'USA'; //
    return address;
  }
  public setCostAndFlatRateHrsForCompEligibleRental(cost: string, flatRateHrs: string) {
    this.costForCompEligible = cost;
    this.flatRateHrsForCompEligible = flatRateHrs;
  }
}