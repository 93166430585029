import { AuthService } from './../../../shared/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Alert, Telematics } from '../../../shared/models/dashboard/telematics.model';
import {
  SwiperComponent,
  SwiperConfigInterface,
  SwiperDirective,
  SwiperPaginationInterface,
  SwiperScrollbarInterface,
} from 'ngx-swiper-wrapper';
import { UserVehicleService } from '../../../shared/services/user-vehicle.service';
import * as util from '../../../shared/services/util.service';
import { DashboardState } from '../../../store/dashboard/dashboard.reducer';
import { Store } from '@ngrx/store';
import { IVehicle } from '@signal/asp-data-commons';
import { Router } from '@angular/router';
import { IDashboardVehicle } from '../../../shared/models/dashboard.model';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { A11yOptions } from 'swiper/types/components/a11y';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss'],
})
export class VehicleCardComponent implements OnInit {

  constructor(private readonly userVehicleService: UserVehicleService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly analyticsService : AnalyticsService,
    private readonly translate : TranslateService) {
    this.disclaimerText = util.GLOBAL_CONSTANT.telematicsDisclaimerText;
  }
  /* vehicle info to be displayed in the card */
  @Input() vehicle: IVehicle;
  @Input() editmode: boolean;
  @Input() newvehicle: IDashboardVehicle;
  @Output() vehicleChange: EventEmitter<IVehicle> = new EventEmitter();
  @Output() toShowFooterDetails: EventEmitter<boolean> = new EventEmitter();
  @Output() fieldChange: EventEmitter<{
    field: string;
    value: string;
  }> = new EventEmitter();

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  setDefaultImg = util.setDefaultImg;
  isCardFlipped = false;
  isTelematicsLoading = true;
  disclaimerText: string;
  telematics: Telematics;
  vinDisplayText: string;
  model:string;
  makeModelYearText: string;
  carImageAbsoluteURL: string;
  inputMileage: number;

  private readonly pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    // dynamicBullets: true
  };

  private readonly scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  };

  public config: SwiperConfigInterface = {
    a11y: {
      enabled: true,
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
    } as A11yOptions,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
  } as SwiperOptions;

  ngOnInit(): void {
    this.vinDisplayText = `VIN ${this.vehicle.vin || this.translate.instant('dashboardAppointments.notAvailable')}`;
    this.makeModelYearText = util.getMakeModelYearText(this.vehicle);
    this.carImageAbsoluteURL = util.getCarImageAbsoluteURL(this.vehicle.imageUrl);
    this.isTelematicsLoading = true;
    const index= this.vehicle.description.indexOf(" ");
    this.model=this.vehicle.description.substr(index);
    this.authService.user.subscribe(user => {
      if (user && !this.authService.isExpired()) {
        this.fetchTelematics(user?.extension_tmsguid);
      }
    });
  }

  private fetchTelematics(userCd: string) {
    this.userVehicleService.getVehicleHealthReport(userCd, this.vehicle.vehicleId || this.vehicle.vin, this.vehicle.vin)
      .subscribe(data => {
        if (data?.status?.code === '200') {
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          this.telematics = Telematics.getTelematics(vehicleStatus, vehicleAlerts);
          if(this.vehicle.vin && this.telematics?.mileage){
            this.onMileageChange(this.telematics?.mileage)
          }
          this.isTelematicsLoading = false;
        } else {
          this.fetchSafetyCampaigns();
        }
       
        const telematicsTrackingData = {
          content_section : 'vehicle tab',
          link_module : 'vehicle details',
          link_text : 'telematics for vehicle loaded'
        };
        this.analyticsService.serviceSchedulerVehicleData = {
          ...this.analyticsService.serviceSchedulerVehicleData,
          mileage : this.telematics?.mileage
        }
        this.analyticsService.trackLink(telematicsTrackingData,true);
      }, err => {
        const telematicsTrackingData = {
          content_section : 'vehicle tab',
          link_module : 'vehicle details',
          link_text : 'error loading telematics for vehicle'
        };
        this.analyticsService.trackLink(telematicsTrackingData,true);
        this.fetchSafetyCampaigns();
        
      });
  }

  private fetchSafetyCampaigns() {
    this.telematics = Telematics.getDefaultTelematics();
    this.userVehicleService.getSpecialServiceCampaign(this.vehicle.vin).subscribe(sscData => {
      if(sscData.status.code === '200'){
        this.telematics.alerts = sscData.vehicleCampaignList.map(val => <Alert>{ message: val.campaignTitle });
      }
      this.isTelematicsLoading = false;
    });
  }

  onMileageChange(input) {
    this.inputMileage = input;
    this.fieldChange.emit({

      field: 'mileage',
      value: input + '',
    });
  }

  triggerOnMileageChange() {
    this.onMileageChange(this.inputMileage || this.vehicle.mileage);
  }

  getTelematicsAlerts() {
    return this.telematics?.alerts;
  }

  flipTheCard() {
    this.isCardFlipped = !this.isCardFlipped;
  }

  mileageBlurHandler(input){
    const mileageTrackingData = {
      content_section : 'vehicle tab',
      module_link : 'vehicle details',
      link_submit_fields : input
    };
    this.analyticsService.serviceSchedulerVehicleData = {
      ...this.analyticsService.serviceSchedulerVehicleData,
      mileage : input
    };
    this.analyticsService.trackLink(mileageTrackingData,true);
  }

  onHistoryClicked() {
    const serviceHistoryTrackingData = {
      content_section : 'vehicle tab',
      link_module : 'vehicle details',
      link_text : 'service history clicked'
    }
    this.analyticsService.trackLink(serviceHistoryTrackingData,true);
    util.navigateToRoutePromise(util.getRouteFromWindow('service') ? `${util.getRouteFromWindow('service')}/servicehistory` : 'service/servicehistory', this.router, {
      queryParams: {
        id: this.vehicle.vehicleId
      }
    });
  }
  emitVehicleDetails(vehicle) {
      this.toShowFooterDetails.emit(true);
  }
  setVehicleMileage(vehicle,editmode){
    if(editmode){
      return  this.telematics?.mileage ?  this.telematics?.mileage : vehicle.appointment.vehicle.mileage;
    }
    return this.telematics?.mileage ? this.telematics?.mileage : '';
  }
  getTranslation(value){
    if(value ){
      const translatedTxt = value?.split(':');
      return this.translate.instant(`${translatedTxt[0]}`) +(translatedTxt[1]==='(Not available)' ? `(${this.translate.instant(`dashboardAppointments.notAvailable`)})` : translatedTxt[1]);
    }
  }
  getAlert(alert){
    if(alert){
    const alertArray = alert?.split(' ');
    const alertValue = alertArray[0]?.toLowerCase()+alertArray[1];
    if(alertArray[0].match(/^[A-Za-z]+$/)){
     return this.translate.instant(`dashboardAppointments.${alertValue}`);
    }else{
      return `${alertArray[0]} ${this.translate.instant(`dashboardAppointments.${alertArray[1].toLowerCase()}`)}`
    }
  }
  }
}
