import {DashboardRoutingModule} from './dashboard-routing.module';
import {NgModule} from '@angular/core';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import {ServiceCardComponent} from './service-card/service-card.component';
import {DashboardComponent} from './dashboard.component';
import {CommonModule} from '@angular/common';
import {AppointmentSectionComponent} from './appointment-section/appointment-section.component';
import {SharedModule} from '../shared/shared.module';
import {SalesCardComponent} from './sales-card/sales-card.component';
import {CardMenuComponent} from './card-menu/card-menu.component';

@NgModule({
    declarations: [
        DashboardComponent,
        AppointmentSectionComponent,
        ServiceCardComponent,
        SalesCardComponent,
        CardMenuComponent
    ],
    imports: [
        DashboardRoutingModule,
        SharedModule,
        MatTooltipModule,
        CommonModule
    ],
    exports: [],
    providers: []
})
export class DashboardModule {
}
