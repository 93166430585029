import { AuthService } from './../../shared/services/auth.service';
import { AfterViewInit, AfterContentChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import {
  selectAppointmentFormAndLookUpData,
  selectAppointmentFormDetailMap,
  selectAvailabilityFormIsRemoteLocation,
  selectAvailabilityFormRemoteZipCode,
  selectVehicleFormImageUrl,
  selectVehicleFormMakeModelYearText,
  selectPMAZipCodesAndRemoteIndicator,
  selectStatesAndCitiesBasedOnZip,
  selectedTransport
} from '../../store/service-appointment/service-appointment.selectors';
import { Observable } from 'rxjs';
import * as util from '../../shared/services/util.service';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionList } from '../../shared/models/asp.types';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { selectServiceAmenities, serviceSettings, transportOptRemote } from '../../store/dealer/dealer.selectors';
import { LoadStatesAndCities } from '../../store/service-appointment/service-appointment.actions';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './../../shared/services/analytics.service';
import { AppointmentFormService } from '../appointment-form.service';

@Component({
  selector: 'app-your-information',
  templateUrl: './your-information.component.html',
  styleUrls: ['./your-information.component.scss']
})
export class YourInformationComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
  @Input() isEditMode: boolean;
  @Input() stepIndex = 3;
  step: UntypedFormGroup;
  transport: string;
  isPickUpLocationSame: boolean = true;
  amenities$: Observable<string[]>;
  view = 'summary';
  makeModelYear = '2020 YARIS HATCHBACK';
  detailMap: { [key: string]: string } = {
    'selectService.time': 'June 16 at 9:00 am',
    'selectService.vehicle': '2020 YARIS HATCHBACK LE\n1230ZESAD91191',
    'transportation.advisor': 'Dave Rowold',
    'selectService.services': '10,000 Miles Checkup - Covered by Toyota Care\nState Inspection - $84.24\nWinshield - $49.94',
    'appointmentInformation.estimatedCost': '$109.23',
    'confirm.coupons': 'Alignment Check',
    "transportation.location": `appointmentInformation.pickupYourVehicleAt: \n1234 Cherry Lane, Irving, TX 75039`
  };
  signedIn: boolean;
  imageUrl$: Observable<string>;
  makeModelYear$: Observable<string>;
  isRemoteLocation: boolean;
  detailMap$: Observable<{ [key: string]: string }>;
  private subs: SubscriptionList = {};
  remoteZipCode$: Observable<string>;
  profile: any;
  remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
  isValidRemoteZip: boolean = true;
  isHomeLocationSame: boolean = true;
  isCustomerAddressMandate: boolean;
  formStates = {
    "customerAddress": { states: [] },
    "pickUpAddress": { states: [] },
    "dropOffAddress": { states: [] }
  }
  formcities = {
    "customerAddress": { cities: { statecities: {} } },
    "pickUpAddress": { cities: { statecities: {} } },
    "dropOffAddress": { cities: { statecities: {} } }
  }
  isEditApptContentNotSet = true;
  isLoginVisible: boolean = true;
  preferredCountries: string = this.translate.currentLang ? this.translate.currentLang.split('-')[1].toLowerCase() : 'us';
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dealerState: Store<DealerState>,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly translate: TranslateService,
    private readonly appointmentFormService: AppointmentFormService
  ) {

    this.step = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.email]],
      isRemoteLocation: [false],
      customerAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        zip: [''],
        stateAbbr: [''],
        isZipValid: [false]
      }),
      pickUpAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        zip: [''],
        stateAbbr: [''],
        isZipValid: [true]
      }),
      dropOffAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        zip: [''],
        stateAbbr: [''],
        isZipValid: [false]
      }),
      otherAddress: [false],
      appointmentComments: [''],
      otherStateAbbr: ['']
    });
    this.isEditApptContentNotSet = true;
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.authService.user.subscribe(user => {
      this.profile = user;
    });
    this.dealerState.select(serviceSettings).subscribe(settings => {
      this.isCustomerAddressMandate = settings?.portal?.mandateCustomerAddress;
      this.isValidRemoteZip = this.isCustomerAddressMandate;
    });

    this.isLoginVisible = (util.countryCd === 'US') ? true : false;


  }

  get f() {
    return this.step.controls;
  }

  get value() {
    return this.step.value;
  }
  ngOnInit(): void {
    this.ready();
  }

  login() {
    this.authService.loginB2c();
  }

  private setValidators() {
    const yourForm = this.step.controls['customerAddress'];
    const pickupForm = this.step.controls['pickUpAddress'];
    const dropoffForm = this.step.controls['dropOffAddress'];
    const addressControls = ['addressLine1', 'city', 'state', 'zip'];
    if (this.step.controls.isRemoteLocation.value) {
      addressControls.forEach(ac => {
        if (ac !== "zip") {
          yourForm.get(ac).setValidators(Validators.required)
        }
      });
    } else {
      addressControls.forEach(ac => {
        if (ac !== "zip") {
          yourForm.get(ac).clearValidators();
          yourForm.get(ac).updateValueAndValidity();
        }
        pickupForm.get(ac).clearValidators();
        pickupForm.get(ac).updateValueAndValidity();
        dropoffForm.get(ac).clearValidators();
        dropoffForm.get(ac).updateValueAndValidity();
      });
    }
    yourForm.updateValueAndValidity();
  }

  ready() {
    this.subs.detailMapSub = this.serviceAppointmentState.select(selectAppointmentFormAndLookUpData)
      .pipe(debounceTime(300))
      .subscribe(d => this.detailMap$ = this.serviceAppointmentState.select(selectAppointmentFormDetailMap));
    this.imageUrl$ = this.serviceAppointmentState.select(selectVehicleFormImageUrl);
    this.makeModelYear$ = this.serviceAppointmentState.select(selectVehicleFormMakeModelYearText);

    this.subs.isRemoteLocationSub = this.serviceAppointmentState.select(selectAvailabilityFormIsRemoteLocation)
      .subscribe(isRemoteLocation => {
        this.isHomeLocationSame = isRemoteLocation;
        this.isRemoteLocation = isRemoteLocation;
        this.step.controls.isRemoteLocation.setValue(isRemoteLocation);

      });

    this.amenities$ = this.dealerState.select(selectServiceAmenities);
    this.subs.zipCodeList = this.serviceAppointmentState.select(selectPMAZipCodesAndRemoteIndicator).subscribe(zipcodes => this.remoteValidZipcodes = zipcodes)
    this.subs.remoteZipCodeSub = this.serviceAppointmentState.select(selectAvailabilityFormRemoteZipCode)
      .subscribe(remoteZipCode => {
        if (!this.isEditMode) {
          this.step.controls['customerAddress'].patchValue({
            zip: remoteZipCode
          });
          this.step.controls['dropOffAddress'].patchValue({
            zip: remoteZipCode
          });
          this.step.controls['pickUpAddress'].patchValue({
            zip: remoteZipCode
          });
          this.dispatchZip(remoteZipCode);
          this.getStateCities(remoteZipCode, 'pickUpAddress');
          this.getStateCities(remoteZipCode, 'customerAddress');
          this.getStateCities(remoteZipCode, 'dropOffAddress');

        }
      });
    this.subs.transportOptRemote = this.dealerState.select(transportOptRemote).subscribe(res => {
      if (res && res.cost) {
        this.appointmentFormService.setCostAndFlatRateHrsForCompEligibleRental(res.cost, res.flatRateHours);
      }
    })

     this.serviceAppointmentState.select(selectedTransport).subscribe(item=>{
      this.transport= item
    });
  }

  blurHandler(fieldName, input) {
    const inputTrackingData = {
      content_section: 'confirm tab',
      link_module: 'contact information',
      link_text: fieldName,
      link_input_field: input.target.value
    }
    this.analyticsService.trackLink(inputTrackingData, true);
  }

  ngAfterViewInit(): void {
    if (this.signedIn) {
      if (this.profile.address) {
        const control = "customerAddress"
        const state = this.profile.address.state
        const city = this.profile.address.city
        this.patchAddressFields(control, { ...this.profile.address, isZipValid: false })
        this.formStates[control].states = [state];
        this.formcities[control].cities.statecities = [{ state, cities: [city] }];
      }
      const form = this.step;
      form.controls.firstName.setValue(this.profile.given_name);
      form.controls.lastName.setValue(this.profile.family_name);
      form.controls.email.setValue(this.profile.email);
    }
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

  updateSwitchValues(val: boolean, property: string) {
    const swtichTrackingData = {
      content_section: 'confirm tab',
      link_module: 'contact information',
      link_text: property,
      link_input_field: val
    }
    this.analyticsService.trackLink(swtichTrackingData, true);
    switch (property) {
      case 'homelocationsame':
        this.isHomeLocationSame = val;
        if (val) {
          this.patchAddressFields('customerAddress', this.step.value.pickUpAddress);
          break;
        }
        this.patchAddressFields('customerAddress', '');
        break;
      case 'pickuplocationsame': {

        this.isPickUpLocationSame = val;
        if (val && this.isHomeLocationSame) {
          this.patchAddressFields('dropOffAddress', this.step.value.pickUpAddress);
          this.patchAddressFields('customerAddress', this.step.value.pickUpAddress);
          break;
        }
        if (val) {
          this.patchAddressFields('dropOffAddress', this.step.value.pickUpAddress);
          break;
        }
        this.patchAddressFields('dropOffAddress', '');
        break;
      }
      default:
        break;
    }
  }

  private patchAddressFields(addressControl: string, updatedValue) {
    this.step.controls[addressControl].patchValue({
      addressLine1: updatedValue.addressLine1,
      addressLine2: updatedValue.addressLine2,
      city: updatedValue.city,
      state: updatedValue.state,
      stateAbbr: updatedValue.stateAbbr,
      zip: updatedValue.zip,
      isZipValid: updatedValue.isZipValid
    });
    if (updatedValue === '') {
      this.step.controls[addressControl].patchValue({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        stateAbbr: '',
        zip: '',
        isZipValid: false
      });
    }
  }

  updateAddressFields(form, control) {
    switch (control) {
      case 'dropOffAddress':
        if (this.isPickUpLocationSame) {
          this.patchAddressFields('dropOffAddress', form);
        }
        break;
      case 'pickUpAddress':
        this.isValidRemoteZip = form.isZipValid;
        if (this.isValidRemoteZip) {
          if (this.isHomeLocationSame && this.isCustomerAddressMandate && this.isPickUpLocationSame) {
            this.patchAddressFields('dropOffAddress', form);
            this.patchAddressFields('customerAddress', form);
            break;
          }
          if (this.isHomeLocationSame && this.isCustomerAddressMandate) {
            this.patchAddressFields('customerAddress', form);
            break;
          }
          if (this.isPickUpLocationSame) {
            this.patchAddressFields('dropOffAddress', form);
            break;
          }
        }
        break;
      default:
        break;
    }
  }
  zipChange(value, control: string) {
    if (value && value.length === 5) {
      this.dispatchZip(value);
      this.getStateCities(value, control)
    }
  }
  getStateCities(value, control) {
    const props = { zipCode: value };
    this.subs.statesAndCities = this.serviceAppointmentState.select(selectStatesAndCitiesBasedOnZip, props).subscribe(response => {
      if (response) {
        this.mapZipCodeResponse(response, control);
      }
    })
  }
  ngAfterContentChecked(): void {
    if (this.isEditApptContentNotSet && this.isEditMode) {
      if ((this.step.value.dropOffAddress?.zip && this.step.value.pickUpAddress?.zip) || (this.step.value.customerAddress.zip)) {
        this.isPickUpLocationSame = false;
        this.isHomeLocationSame = false;
        let value = '';
        if ((this.step.value.dropOffAddress?.zip && this.step.value.pickUpAddress?.zip)) {
          value = this.step.value.dropOffAddress?.zip + "," + this.step.value.pickUpAddress?.zip;
        }
        if (this.step.value.customerAddress?.zip) {
          if (value) {
            value += "," + this.step.value.customerAddress.zip;
          }
          else {
            value = this.step.value.customerAddress.zip;
          }
        }
        this.dispatchZip(value);
        if ((this.step.value.dropOffAddress?.zip && this.step.value.pickUpAddress?.zip)) {
          this.getStateCities(this.step.value.dropOffAddress?.zip, "dropOffAddress");
          this.getStateCities(this.step.value.pickUpAddress?.zip, "pickUpAddress");
        }
        if (this.step.value.customerAddress?.zip) {
          this.getStateCities(this.step.value.customerAddress?.zip, "customerAddress");
        }
        this.isEditApptContentNotSet = false;
      }
    }
  }
  dispatchZip(value) {
    if (value && value.length >= 5) {
      this.serviceAppointmentState.dispatch(new LoadStatesAndCities({ zipPinCode: value }))
    }
  }

  private mapZipCodeResponse(response, control) {
    if (response.stateCity && response.stateCity.length > 0 && this.step.value[control].zip === response.postalCode) {
      if (response.stateCity.length === 1) {
        this.step.controls[control].patchValue({ state: response.stateCity[0].state })
        this.formStates[control].states = [response.stateCity[0].state];
        this.formcities[control].cities.statecities = response.stateCity;
        if (response.stateCity[0].cities.length === 1) {
          this.step.controls[control].patchValue({ city: response.stateCity[0].cities[0] })
        }
      }
      else {
        const states = [];
        let cities = {}
        for (const statecities of response.stateCity) {
          states.push(statecities.state);
          cities = { ...cities, statecities: response.stateCity }
        }
        this.formStates[control].states = states;
        this.formcities[control].cities = cities;
      }
    }
    else {
      if (this.step.value[control].zip === response.postalCode) {
        this.step.controls[control].get('zip').setErrors({ required: this.translate.instant('appointmentInformation.invalidZip') });
        this.step.controls[control].get('zip').setValidators(Validators.required);
      }
    }
  }
}
